import { memo, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useToggle } from 'hooks/useToggle';
import { useIsDesktop } from 'hooks/useBreakpoints';
import { useLayout } from 'store/layout';
import { notEmpty } from 'utils/notEmpty';
import { MenuItemsLeft } from './MenuItemsLeft';
import { MenuItemsRight } from './MenuItemsRight';
import s from './Header.module.scss';

export const Header = memo(() => {
  const [isScrollTop, setIsScrollTop] = useState(true);

  const { layout } = useLayout();
  const searchToggle = useToggle();
  const languageToggle = useToggle();
  const burgerToggle = useToggle();

  const [isSearchOpen] = searchToggle;
  const [isLanguageMenuOpen] = languageToggle;
  const [isBurgerMenuOpen] = burgerToggle;

  const isDesktop = useIsDesktop();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrollTop(false);
      } else {
        setIsScrollTop(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const menuOptions = useMemo(() => layout?.menuCategories.filter(notEmpty), [layout?.menuCategories]);

  const isAbove = !isScrollTop || isSearchOpen || ((isLanguageMenuOpen || isBurgerMenuOpen) && !isDesktop);

  return (
    <header className={clsx(s.Header, { [s.Header_above]: isAbove })}>
      <div className={s.Header__content}>
        <MenuItemsLeft key={layout?.locale} menuOptions={menuOptions} />
        <MenuItemsRight
          menuOptions={menuOptions}
          searchToggle={searchToggle}
          languageToggle={languageToggle}
          burgerToggle={burgerToggle}
        />
      </div>
    </header>
  );
});

Header.displayName = 'Header';
