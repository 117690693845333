import React, { useCallback, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Options } from '@popperjs/core';
import { IconsTypes } from 'components/Icon';
import { IconButton, IconButtonVariant } from 'components/IconButton';
import { ComponentLayoutMenuCategory } from 'store/graphql-strapi';
import { Portal } from 'components/Portal';
import { DropdownMenu } from 'components/DropdownMenu';
import { useToggle } from 'hooks/useToggle';
import { useClickOutside } from 'hooks/useClickOutside';
import { notEmpty } from 'utils/notEmpty';
import { MenuKebabItem } from './MenuKebabItem';
import s from './MenuKebab.module.scss';

export const popperOptions: Partial<Options> = {
  strategy: 'fixed',
  placement: 'bottom-start',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [24, -2]
      }
    },
    {
      name: 'preventOverflow',
      options: {
        rootBoundary: 'viewport',
        tether: false,
        altAxis: true
      }
    },
    {
      name: 'eventListeners',
      options: {
        scroll: false
      }
    }
  ]
};

export interface MenuKebabProps {
  options: ComponentLayoutMenuCategory[];
}

export const MenuKebab: React.FC<MenuKebabProps> = (props) => {
  const { options } = props;
  const [isDropdownMenuOpen, { toggle, unset }] = useToggle();
  const [menuItemOpen, setMenuItemOpen] = useState<string | null>(null);

  const root = useRef<HTMLDivElement>(null);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(root.current, popperElement, {
    ...popperOptions
  });

  const onMenuItemClick = useCallback(
    (title: string) => {
      title === menuItemOpen ? setMenuItemOpen(null) : setMenuItemOpen(title);
    },
    [menuItemOpen]
  );

  const onOptionClick = useCallback(() => {
    setMenuItemOpen(null);
    unset();
  }, [unset]);

  const onClickHandler = useCallback(() => {
    setMenuItemOpen(null);
    toggle();
  }, [toggle]);

  useClickOutside(dropdownMenuRef, isDropdownMenuOpen ? onOptionClick : null);

  return (
    <div ref={root}>
      <IconButton
        className={s.MenuKebab}
        icon={IconsTypes.ARROW_KEBAB}
        variant={IconButtonVariant.Tertiary}
        onClick={onClickHandler}
      />
      {isDropdownMenuOpen && (
        <Portal>
          <div
            ref={setPopperElement}
            className={s.MenuKebab__dropdown}
            style={{
              ...styles.popper
            }}
            {...attributes.popper}>
            <DropdownMenu ref={dropdownMenuRef}>
              {options?.map(({ id, title, menuItems }) => {
                const filteredMenuItems = (menuItems ?? []).filter(notEmpty);

                return (
                  filteredMenuItems.length && (
                    <MenuKebabItem
                      key={id}
                      text={title}
                      isOpen={menuItemOpen === title}
                      menuItemOptions={filteredMenuItems}
                      onOptionClick={onOptionClick}
                      onMenuItemClick={onMenuItemClick}
                    />
                  )
                );
              })}
            </DropdownMenu>
          </div>
        </Portal>
      )}
    </div>
  );
};
