import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { ApolloProvider } from '@apollo/client';
import { ReactFCC } from 'utils/helperTypes';
import { LocaleProvider } from 'store/locale';
import { FullScreenLoader } from 'components/Loader';
import { ERROR_PAGE_ROUTE } from 'routes/routes';
import { Head } from 'components/Head';
import { LayoutProvider } from 'store/layout';
import { SearchProvider } from 'store/search';
import { IntlProvider } from './IntlProvider';
import { strapiApolloClient } from './apollo';

export const AppProvider: ReactFCC = ({ children }) => (
  <React.Suspense fallback={<FullScreenLoader />}>
    <ApolloProvider client={strapiApolloClient}>
      <LocaleProvider>
        <LayoutProvider>
          <IntlProvider>
            <SearchProvider>
              <HelmetProvider>
                <BrowserRouter>
                  <ErrorBoundary
                    fallbackRender={({ resetErrorBoundary }) => {
                      setTimeout(resetErrorBoundary, 0);
                      return <Navigate to={ERROR_PAGE_ROUTE} />;
                    }}>
                    <Head />
                    {children}
                  </ErrorBoundary>
                </BrowserRouter>
              </HelmetProvider>
            </SearchProvider>
          </IntlProvider>
        </LayoutProvider>
      </LocaleProvider>
    </ApolloProvider>
  </React.Suspense>
);
