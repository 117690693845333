import { LocalStorageKeys } from 'store/localStorageKeys';

const storage = {
  getItem: (key: LocalStorageKeys) => {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  },
  setItem: (key: LocalStorageKeys, value: any) => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
};

export default storage;
