export const getLocaleDisplayName = (locale?: string) => {
  switch (locale) {
    case 'ru':
      return 'RUS';
    case 'uk':
      return 'UKR';
    case 'tg':
      return 'TJK';
    case 'ru-MD':
      return 'MDA';
    case 'ro':
      return 'MDA';
    case 'uz':
      return 'UZB';
    case 'az':
      return 'AZE';
    case 'kk-Cyrl-KZ':
      return 'KZ';
    default:
      return locale?.toUpperCase() ?? '';
  }
};
