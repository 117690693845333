import React, { memo } from 'react';
import clsx from 'clsx';
import { Loader, LoaderSize } from '../Loader';
import s from './PageLoader.module.scss';

export interface PageLoaderProps {
  className?: string;
}

export const PageLoader: React.FC<PageLoaderProps> = memo((props) => {
  const { className } = props;

  return (
    <div className={clsx(s.PageLoader, className)}>
      <Loader size={LoaderSize.large} />
    </div>
  );
});

PageLoader.displayName = 'PageLoader';
