import { memo } from 'react';
import { AdditionallyCard, AdditionallyCardVariant } from 'features/AdditionallyCard';
import { ComponentLayoutSearchQueries } from 'store/graphql-strapi';
import s from './PopularQueries.module.scss';

export interface PopularQueriesProps {
  searchLabel?: string | null;
  queries?: ComponentLayoutSearchQueries[] | null;
  onChangeSearchValue: (value: string) => void;
}

export const PopularQueries = memo((props: PopularQueriesProps) => {
  const { searchLabel, queries, onChangeSearchValue } = props;

  if (!queries) {
    return null;
  }

  return (
    <div className={s.PopularQueries}>
      {searchLabel && <p className={s.PopularQueries__title}>{searchLabel}</p>}
      {queries.map(({ id, query }) => (
        <AdditionallyCard
          key={id}
          text={query}
          variant={AdditionallyCardVariant.secondary}
          onClick={() => onChangeSearchValue(query)}
        />
      ))}
    </div>
  );
});

PopularQueries.displayName = 'PopularQueries';
