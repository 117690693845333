import { LinkType } from 'store/graphql-strapi';
import {
  CONTACT_PAGE_PATH,
  INFO_PAGE_PATH,
  NOT_FOUND_PAGE_ROUTE,
  SEARCH_PAGE_ROUTE,
  SERVICE_PAGE_PATH,
  URL_KEY_SEARCH
} from 'routes/routes';
import { exhaustiveCheck } from 'utils/exhaustiveCheck';

export class PathBuilder {
  static GALLERY_PAGE_PATH: string | null = null;
  static NEWS_LIST_PAGE_PATH: string | null = null;

  static getPathByPageType = (linkType: LinkType, uri?: string) => {
    switch (linkType) {
      case LinkType.InfoPage:
        return `${INFO_PAGE_PATH}/${uri}`;
      case LinkType.ContactPage:
        return `${CONTACT_PAGE_PATH}/${uri}`;
      case LinkType.ServicePage:
        return `${SERVICE_PAGE_PATH}/${uri}`;
      case LinkType.NewsListPage:
        return PathBuilder.NEWS_LIST_PAGE_PATH ? `/${PathBuilder.NEWS_LIST_PAGE_PATH}` : NOT_FOUND_PAGE_ROUTE;
      case LinkType.NewsPage:
        return PathBuilder.NEWS_LIST_PAGE_PATH ? `/${PathBuilder.NEWS_LIST_PAGE_PATH}/${uri}` : NOT_FOUND_PAGE_ROUTE;
      case LinkType.GalleryPage:
        return PathBuilder.GALLERY_PAGE_PATH ? `/${PathBuilder.GALLERY_PAGE_PATH}` : NOT_FOUND_PAGE_ROUTE;
      case LinkType.MediaPage:
        return PathBuilder.GALLERY_PAGE_PATH ? `/${PathBuilder.GALLERY_PAGE_PATH}/${uri}` : NOT_FOUND_PAGE_ROUTE;
      case LinkType.ExternalLink:
        return uri ?? NOT_FOUND_PAGE_ROUTE;
      default:
        exhaustiveCheck(linkType);
        return NOT_FOUND_PAGE_ROUTE;
    }
  };

  static getSearchPath = (search: string) => {
    return `${SEARCH_PAGE_ROUTE}?${URL_KEY_SEARCH}=${search}`;
  };
}
