import React, { PropsWithChildren, useLayoutEffect, useMemo } from 'react';
import { Layout, LinkType, useLayoutQuery } from 'store/graphql-strapi';
import { useLocale } from 'store/locale';
import { PathBuilder } from 'routes/PathBuilder';
import { LayoutContext } from './LayoutContext';

export const LayoutProvider = ({ children }: PropsWithChildren) => {
  const { locale, loading: localeLoading, error: localeError } = useLocale();

  const {
    data,
    loading: layoutLoading,
    error: layoutError
  } = useLayoutQuery({
    variables: {
      id: locale.layoutId,
      locale: locale.name
    },
    skip: !locale.layoutId
  });

  const { layout, routes, defaultDescription } = useMemo(() => {
    const routeGallery = data?.galleryPage?.data?.attributes?.slug;
    const routeNewsList = data?.newsListPage?.data?.attributes?.slug;

    if (routeGallery) {
      PathBuilder.GALLERY_PAGE_PATH = routeGallery;
    }
    if (routeNewsList) {
      PathBuilder.NEWS_LIST_PAGE_PATH = routeNewsList;
    }

    return {
      layout: data?.layout?.data?.attributes as Layout,
      routes: {
        [LinkType.GalleryPage]: routeGallery,
        [LinkType.NewsListPage]: routeNewsList
      },
      defaultDescription: data?.mainPage?.data?.attributes?.metaDescription
    };
  }, [data]);

  useLayoutEffect(() => {
    if (routes[LinkType.GalleryPage]) {
      PathBuilder.GALLERY_PAGE_PATH = routes[LinkType.GalleryPage] as string;
    }
    if (routes[LinkType.NewsListPage]) {
      PathBuilder.NEWS_LIST_PAGE_PATH = routes[LinkType.NewsListPage] as string;
    }
  }, [routes]);

  const loading = localeLoading || layoutLoading;
  const error = localeError || layoutError;

  return (
    <LayoutContext.Provider
      value={{
        layout,
        routes,
        defaultDescription,
        loading,
        error
      }}>
      {children}
    </LayoutContext.Provider>
  );
};
