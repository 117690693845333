import React, { PropsWithChildren, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import storage from 'utils/storage';
import { LOCAL_STORAGE_LOCALE } from 'store/localStorageKeys';
import { useLocaleQuery } from 'store/graphql-strapi';
import { getQueryParamValue } from 'utils/getQueryParam';
import { DEFAULT_LOCALE } from './utils/constants';
import { getLocaleDisplayName } from './utils/getLocaleDisplayName';
import { ILocale, initialLocale, LocaleContext } from './LocaleContext';
import { useGetMyLocaleIp } from './useGetMyLocaleIp';

export const LocaleProvider = ({ children }: PropsWithChildren) => {
  const { data, loading, error } = useLocaleQuery();

  const [locale, setLocale] = useState<ILocale>(initialLocale);
  const { handleGetLocaleIp, error: errorIp, loading: loadingIp } = useGetMyLocaleIp();
  const [isVisibleTooltip, setVisibleTooltip] = useState(false);

  const availableLocales = useMemo(() => {
    const locales: ILocale[] = [];
    const localesName: string[] = [];
    let defaultLocale: ILocale | null = null;

    data?.layouts?.data.forEach((layout) => {
      if (!layout.id || !layout.attributes?.locale || localesName.includes(layout.attributes.locale)) {
        return;
      }

      localesName.push(layout.attributes.locale);

      const item = {
        layoutId: layout.id,
        name: layout.attributes.locale,
        displayName: getLocaleDisplayName(layout.attributes.locale)
      };

      if (item.name === DEFAULT_LOCALE) {
        defaultLocale = item;
      } else {
        locales.push(item);
      }
    });

    locales.sort((a, b) => a.displayName.localeCompare(b.displayName));

    return defaultLocale ? [defaultLocale, ...locales] : locales;
  }, [data]);

  useLayoutEffect(() => {
    const initializeLocale = async () => {
      if (!data) return;

      const queryLang = getQueryParamValue('lang');

      if (queryLang) {
        const localeQuery = availableLocales.find(({ name }) => name === queryLang);
        if (localeQuery) {
          setLocale(localeQuery);
          storage.setItem(LOCAL_STORAGE_LOCALE, localeQuery);
          return;
        }
      }

      const storageLocale: ILocale | null = storage.getItem(LOCAL_STORAGE_LOCALE);

      if (storageLocale) {
        const validLocale = availableLocales.find(
          ({ name, layoutId }) => name === storageLocale?.name && layoutId === storageLocale?.layoutId
        );
        if (validLocale) {
          setLocale(validLocale);
          storage.setItem(LOCAL_STORAGE_LOCALE, validLocale);
          return;
        }
      }

      const ipLocale = await handleGetLocaleIp();

      if (ipLocale) {
        const validIpLocale = availableLocales.find(({ name }) => name === ipLocale);
        if (validIpLocale) {
          setLocale(validIpLocale);
          storage.setItem(LOCAL_STORAGE_LOCALE, validIpLocale);
          setVisibleTooltip(true);
        } else {
          const defaultLocale = availableLocales.find(({ name }) => name === DEFAULT_LOCALE);
          if (defaultLocale) {
            setLocale(defaultLocale);
            storage.setItem(LOCAL_STORAGE_LOCALE, defaultLocale);
          }
        }
      }
    };

    if (!errorIp) {
      void initializeLocale();
    }

    if (errorIp) {
      const defaultLocale = availableLocales.find(({ name }) => name === DEFAULT_LOCALE);
      if (defaultLocale) {
        setLocale(defaultLocale);
        storage.setItem(LOCAL_STORAGE_LOCALE, defaultLocale);
      }
    }
  }, [availableLocales, data, handleGetLocaleIp, errorIp]);

  const setLocaleHandler = useCallback(
    (newLocale: ILocale) => {
      if (!availableLocales.find(({ name }) => name === newLocale.name)) {
        return;
      }

      setLocale(newLocale);
      storage.setItem(LOCAL_STORAGE_LOCALE, newLocale);
    },
    [availableLocales]
  );

  return (
    <LocaleContext.Provider
      value={{
        locale,
        loading: loadingIp || loading,
        error,
        availableLocales,
        setLocale: setLocaleHandler,
        visibleLanguageTooltip: isVisibleTooltip,
        languageTooltipHandler: setVisibleTooltip
      }}>
      {children}
    </LocaleContext.Provider>
  );
};
