import { useMemo } from 'react';
import { notEmpty } from 'utils/notEmpty';
import { LinkType } from 'store/graphql-strapi';
import { useLayout } from '../useLayout';

export const useGetCategoryBySlug = (linkType?: LinkType, slug?: string | null) => {
  const { layout } = useLayout();

  return useMemo(() => {
    if (!slug || !linkType) {
      return {
        categoryBySlug: null,
        menuItemTitleBySlug: null
      };
    }

    let menuItemTitleBySlug: string | null | undefined = null;

    const categoryBySlug = layout?.menuCategories.filter(notEmpty).find(({ menuItems }) => {
      menuItemTitleBySlug = menuItems.find((item) => item?.link.uri === slug && item?.link.type === linkType)?.title;
      return !!menuItemTitleBySlug;
    })?.title;

    return {
      categoryBySlug,
      menuItemTitleBySlug
    };
  }, [layout?.menuCategories, linkType, slug]);
};
