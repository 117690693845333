import { memo, useCallback, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Options } from '@popperjs/core';
import clsx from 'clsx';
import { Icon, IconSize, IconsTypes } from 'components/Icon';
import { DropdownMenu, DropdownMenuItem } from 'components/DropdownMenu';
import { useClickOutside } from 'hooks/useClickOutside';
import { ComponentNavigationMenuItem } from 'store/graphql-strapi';
import { Portal } from 'components/Portal';
import { NavigationLink } from 'features/NavigationLink';
import s from './MenuItem.module.scss';

export interface MenuItemProps {
  isOpen?: boolean;
  text: string;
  onMenuItemClick?: (title: string | null) => void;
  menuItemOptions: ComponentNavigationMenuItem[];
}

export const popperOptions: Partial<Options> = {
  strategy: 'fixed',
  placement: 'bottom-start',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [-20, 10]
      }
    },
    {
      name: 'preventOverflow',
      options: {
        rootBoundary: 'viewport',
        tether: false,
        altAxis: true
      }
    },
    {
      name: 'eventListeners',
      options: {
        scroll: false
      }
    }
  ]
};

export const MenuItem = memo((props: MenuItemProps) => {
  const { isOpen, text, onMenuItemClick, menuItemOptions } = props;

  const root = useRef<HTMLDivElement>(null);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(root.current, popperElement, {
    ...popperOptions
  });

  const onClickHandler = useCallback(() => {
    onMenuItemClick?.(text);
  }, [onMenuItemClick, text]);

  const onOptionClick = useCallback(() => {
    onMenuItemClick?.(null);
  }, [onMenuItemClick]);

  useClickOutside(dropdownMenuRef, isOpen ? onOptionClick : null);

  return (
    <>
      <div className={clsx(s.MenuItem, isOpen && s.MenuItem_open)} onClick={onClickHandler} ref={root}>
        <p className={s.MenuItem__text}>{text}</p>
        <Icon
          className={clsx(s.MenuItem__icon, isOpen && s.MenuItem__icon_open)}
          icon={IconsTypes.ARROW_CHEVRON_DOWN}
          size={IconSize.Small}
        />
      </div>
      {isOpen && (
        <Portal>
          <div
            ref={setPopperElement}
            className={s.MenuItem__dropdown}
            style={{
              ...styles.popper
            }}
            {...attributes.popper}>
            <DropdownMenu ref={dropdownMenuRef}>
              {menuItemOptions?.map(({ id, title, link }) => (
                <NavigationLink key={id} link={link}>
                  <DropdownMenuItem onClick={onOptionClick}>{title}</DropdownMenuItem>
                </NavigationLink>
              ))}
            </DropdownMenu>
          </div>
        </Portal>
      )}
    </>
  );
});

MenuItem.displayName = 'MenuItem';
