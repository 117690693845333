import { memo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, IconButtonVariant } from 'components/IconButton';
import { IconSize, IconsTypes } from 'components/Icon';
import { useClickOutside } from 'hooks/useClickOutside';
import { Portal } from 'components/Portal';
import { useIsDesktop } from 'hooks/useBreakpoints';
import { PathBuilder } from 'routes/PathBuilder';
import { SearchInput } from 'features/SearchInput';
import { useLayout } from 'store/layout';
import { notEmpty } from 'utils/notEmpty';
import { PopularQueries } from './PopularQueries';
import s from './Search.module.scss';

export interface SearchProps {
  isOpen?: boolean;
  closeSearch: () => void;
  toggleSearch: () => void;
}

export const Search = memo((props: SearchProps) => {
  const { isOpen, closeSearch, toggleSearch } = props;

  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');

  const { layout } = useLayout();

  const searchRef = useRef<HTMLDivElement>(null);

  useClickOutside(searchRef, isOpen ? closeSearch : null);

  const isDesktop = useIsDesktop();

  const onSearch = (value: string) => {
    if (!value) {
      return;
    }

    closeSearch();
    setSearchValue('');
    navigate(PathBuilder.getSearchPath(value));
  };

  return (
    <>
      <IconButton
        icon={IconsTypes.SEARCH}
        variant={IconButtonVariant.Tertiary}
        iconSize={IconSize.Medium}
        onClick={toggleSearch}
      />
      {isOpen && (
        <Portal>
          <div className={s.Search} ref={searchRef}>
            <div className={s.Search__content}>
              {isDesktop && (
                <IconButton
                  variant={IconButtonVariant.Tertiary}
                  className={s.Search__close}
                  icon={IconsTypes.CROSS}
                  onClick={closeSearch}
                />
              )}
              <SearchInput searchValue={searchValue} onChangeSearch={setSearchValue} onSearch={onSearch} />
              <PopularQueries
                searchLabel={layout?.searchLabel}
                queries={layout?.searchQueries?.filter(notEmpty)}
                onChangeSearchValue={(queryValue) => onSearch(queryValue)}
              />
            </div>
          </div>
        </Portal>
      )}
    </>
  );
});

Search.displayName = 'Search';
