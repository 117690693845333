import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';
import { Input } from 'components/Input';
import { IconsTypes } from 'components/Icon';
import { Button } from 'components/Button';
import s from './SearchInput.module.scss';

export interface SearchInputProps {
  className?: string;
  searchValue: string;
  onChangeSearch: (value: string) => void;
  onSearch: (value: string) => void;
}

export const SearchInput: FC<SearchInputProps> = (props) => {
  const { className, searchValue, onChangeSearch, onSearch } = props;

  const intl = useIntl();

  return (
    <div className={clsx(s.SearchInput__wrapper, className)}>
      <Input
        className={s.SearchInput__input}
        leftIcon={IconsTypes.SEARCH}
        placeholder={intl.formatMessage({ defaultMessage: 'Поиск' })}
        value={searchValue}
        onChange={onChangeSearch}
        onKeyDown={(e) => e.key === 'Enter' && onSearch(searchValue)}
      />
      <Button className={s.SearchInput__findButton} onClick={() => onSearch(searchValue)}>
        <FormattedMessage defaultMessage={'Найти'} />
      </Button>
    </div>
  );
};
