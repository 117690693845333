import { memo, RefObject, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Options } from '@popperjs/core';
import clsx from 'clsx';
import { Button, ButtonVariant } from 'components/Button';
import { DropdownMenu } from 'components/DropdownMenu';
import { Portal } from 'components/Portal';
import { useClickOutside } from 'hooks/useClickOutside';
import { ComponentLayoutMenuCategory } from 'store/graphql-strapi';
import { BurgerMenuItem } from '../BurgerMenuItem';
import s from './BurgerMenu.module.scss';

export interface BurgerMenuProps {
  className?: string;
  rootRef: RefObject<HTMLDivElement>;
  closeBurgerMenu: () => void;
  menuOptions?: ComponentLayoutMenuCategory[];
}

const popperOptions: Partial<Options> = {
  strategy: 'fixed',
  placement: 'bottom-start',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 0]
      }
    },
    {
      name: 'preventOverflow',
      options: {
        rootBoundary: 'viewport',
        tether: false,
        altAxis: true
      }
    }
  ]
};

export const BurgerMenu = memo((props: BurgerMenuProps) => {
  const { rootRef, menuOptions, closeBurgerMenu, className } = props;

  const dropdownMenuRef = useRef<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(rootRef.current, popperElement, {
    ...popperOptions
  });

  useClickOutside(dropdownMenuRef, closeBurgerMenu);

  return (
    <Portal>
      <div
        className={clsx(s.BurgerMenu, className)}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}>
        <DropdownMenu
          ref={dropdownMenuRef}
          classes={{ items: s.BurgerMenu__dropdownItems, root: s.BurgerMenu__dropdownMenu }}>
          {menuOptions?.map((menuOption) => (
            <BurgerMenuItem key={menuOption.id} menuOption={menuOption} closeBurgerMenu={closeBurgerMenu} />
          ))}
          <div className={s.BurgerMenu__buttons}>
            <Button
              Component={Link}
              to={
                'https://mmc.mos.ru/client-office/security/auth-rvg/login/check?3&service=http://mmc.mos.ru/client-office/auth/signin-cas'
              }
              target={'_blank'}
              rel={'noopener noreferrer'}
              variant={ButtonVariant.PrimaryBlue}
              className={s.BurgerMenu__button}>
              <FormattedMessage defaultMessage={'РВП, ВНЖ, Гражданство РФ'} />
            </Button>
            <Button
              Component={Link}
              to={
                'https://oibmmc.mos.ru/auth-mmc-lk/login?1&service=http://lkmmc.mos.ru/mmcinfosupplier/oib-auth/check'
              }
              target={'_blank'}
              rel={'noopener noreferrer'}
              variant={ButtonVariant.PrimaryRed}
              className={s.BurgerMenu__button}>
              <FormattedMessage defaultMessage={'Патент'} />
            </Button>
          </div>
        </DropdownMenu>
      </div>
    </Portal>
  );
});

BurgerMenu.displayName = 'BurgerMenu';
