import { ReactComponent as ArrowChevronDown } from './icons/arrow-chevron-down.svg';
import { ReactComponent as ArrowChevronLeft } from './icons/arrow-chevron-left.svg';
import { ReactComponent as ArrowChevronRight } from './icons/arrow-chevron-right.svg';
import { ReactComponent as ArrowChevronUp } from './icons/arrow-chevron-up.svg';
import { ReactComponent as ArrowKebab } from './icons/arrow-kebab.svg';
import { ReactComponent as ArrowLeft } from './icons/arrow-left.svg';
import { ReactComponent as ArrowLink } from './icons/arrow-link.svg';
import { ReactComponent as ArrowRight } from './icons/arrow-right.svg';
import { ReactComponent as Attachment } from './icons/attachment.svg';
import { ReactComponent as Burger } from './icons/burger.svg';
import { ReactComponent as CircleIcon } from './icons/circe.svg';
import { ReactComponent as CrossIcon } from './icons/cross.svg';
import { ReactComponent as DocumentIcon } from './icons/document.svg';
import { ReactComponent as Loader } from './icons/loader.svg';
import { ReactComponent as Patent } from './icons/patent.svg';
import { ReactComponent as PictureIcon } from './icons/picture.svg';
import { ReactComponent as PinIcon } from './icons/pin.svg';
import { ReactComponent as Refresh } from './icons/refresh.svg';
import { ReactComponent as SearchIcon } from './icons/search.svg';
import { ReactComponent as UserIcon } from './icons/user.svg';
import { ReactComponent as VideoIcon } from './icons/video.svg';
import { ReactComponent as QuestionFilled } from './icons/question-filled.svg';
import { ReactComponent as QuestionOutlined } from './icons/question-outlined.svg';

export enum IconsTypes {
  ARROW_CHEVRON_DOWN = 'ARROW_CHEVRON_DOWN',
  ARROW_CHEVRON_LEFT = 'ARROW_CHEVRON_LEFT',
  ARROW_CHEVRON_RIGHT = 'ARROW_CHEVRON_RIGHT',
  ARROW_CHEVRON_UP = 'ARROW_CHEVRON_UP',
  ARROW_KEBAB = 'ARROW_KEBAB',
  ARROW_LEFT = 'ARROW_LEFT',
  ARROW_LINK = 'ARROW_LINK',
  ARROW_RIGHT = 'ARROW_RIGHT',
  BURGER = 'BURGER',
  CIRCLE = 'CIRCLE',
  CROSS = 'CROSS',
  DOCUMENT = 'DOCUMENT',
  LOADER = 'LOADER',
  PATENT = 'PATENT',
  PICTURE = 'PICTURE',
  PIN = 'PIN',
  REFRESH = 'REFRESH',
  SEARCH = 'SEARCH',
  USER = 'USER',
  VIDEO = 'VIDEO',
  ATTACHMENT = 'ATTACHMENT',
  QUESTION_FILLED = 'QUESTION_FILLED',
  QUESTION_OUTLINED = 'QUESTION_OUTLINED'
}

type IconsMap = {
  [key in IconsTypes]: JSX.Element;
};

export const ICONS_MAP: IconsMap = {
  [IconsTypes.ARROW_CHEVRON_DOWN]: <ArrowChevronDown />,
  [IconsTypes.ARROW_CHEVRON_LEFT]: <ArrowChevronLeft />,
  [IconsTypes.ARROW_CHEVRON_RIGHT]: <ArrowChevronRight />,
  [IconsTypes.ARROW_CHEVRON_UP]: <ArrowChevronUp />,
  [IconsTypes.ARROW_LEFT]: <ArrowLeft />,
  [IconsTypes.ATTACHMENT]: <Attachment />,
  [IconsTypes.ARROW_LINK]: <ArrowLink />,
  [IconsTypes.ARROW_RIGHT]: <ArrowRight />,
  [IconsTypes.ARROW_KEBAB]: <ArrowKebab />,
  [IconsTypes.BURGER]: <Burger />,
  [IconsTypes.CIRCLE]: <CircleIcon />,
  [IconsTypes.CROSS]: <CrossIcon />,
  [IconsTypes.DOCUMENT]: <DocumentIcon />,
  [IconsTypes.LOADER]: <Loader />,
  [IconsTypes.PATENT]: <Patent />,
  [IconsTypes.PICTURE]: <PictureIcon />,
  [IconsTypes.PIN]: <PinIcon />,
  [IconsTypes.REFRESH]: <Refresh />,
  [IconsTypes.SEARCH]: <SearchIcon />,
  [IconsTypes.USER]: <UserIcon />,
  [IconsTypes.VIDEO]: <VideoIcon />,
  [IconsTypes.QUESTION_FILLED]: <QuestionFilled />,
  [IconsTypes.QUESTION_OUTLINED]: <QuestionOutlined />
};
