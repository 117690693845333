import { memo, ReactNode } from 'react';
import clsx from 'clsx';
import s from './DropdownMenuItem.module.scss';

export interface DropdownMenuItemProps {
  className?: string;
  children: ReactNode;
  isActive?: boolean;
  onClick?: () => void;
}

export const DropdownMenuItem = memo((props: DropdownMenuItemProps) => {
  const { className, isActive, onClick, children } = props;

  return (
    <div onClick={onClick} className={clsx(s.DropdownMenuItem, { [s.DropdownMenuItem__active]: isActive }, className)}>
      {children}
    </div>
  );
});

DropdownMenuItem.displayName = 'DropdownMenuItem';
