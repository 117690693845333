import { memo } from 'react';
import clsx from 'clsx';
import { ComponentLayoutMenuCategory } from 'store/graphql-strapi';
import { NavigationLink } from 'features/NavigationLink';
import s from './FooterLinksCard.module.scss';

export interface FooterLinksCardProps {
  className?: string;
  options: ComponentLayoutMenuCategory;
}
export const FooterLinksCard = memo((props: FooterLinksCardProps) => {
  const { className, options } = props;

  return (
    <div className={clsx(s.FooterLinksCard, className)}>
      <p className={s.FooterLinksCard__category}>{options.title}</p>
      <div className={s.FooterLinksCard__links}>
        {(options.menuItems ?? []).map(
          (item) =>
            item && (
              <NavigationLink key={item.id} className={s.FooterLinksCard__link} link={item.link}>
                {item.title}
              </NavigationLink>
            )
        )}
      </div>
    </div>
  );
});

FooterLinksCard.displayName = 'FooterLinksCard';
