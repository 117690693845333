import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useLayout } from 'store/layout';

export interface HeadProps {
  title?: string;
  description?: string | null;
}

export const Head = ({ title = '', description = '' }: HeadProps) => {
  const intl = useIntl();

  const { defaultDescription } = useLayout();
  const defaultTitle = intl.formatMessage({
    defaultMessage: 'Многофункциональный миграционный центр'
  });

  const descriptionContent = description || defaultDescription;

  return (
    <Helmet title={title ? `${title} — ${defaultTitle}` : undefined} defaultTitle={defaultTitle}>
      {descriptionContent && <meta name="description" content={descriptionContent} />}
    </Helmet>
  );
};
