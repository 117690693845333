import { FormattedMessage } from 'react-intl';
import { Hero, HeroContent } from 'components/Hero';

export const NotFoundPage = () => {
  return (
    <Hero align={'center'}>
      <HeroContent
        title={<FormattedMessage defaultMessage={'Страница не найдена'} />}
        text={
          <FormattedMessage
            defaultMessage={
              ' Возможно страница устарела, была удалена,{br}или был введен неверный адрес в адресной строке.'
            }
            values={{ br: <br /> }}
          />
        }
      />
    </Hero>
  );
};
