import { memo } from 'react';
import clsx from 'clsx';
import s from './AdditionallyCard.module.scss';

export enum AdditionallyCardVariant {
  primary = 'primary',
  secondary = 'secondary'
}
export interface AdditionallyCardProps {
  className?: string;
  text: string;
  variant?: AdditionallyCardVariant;
  onClick?: () => void;
}

export const AdditionallyCard = memo((props: AdditionallyCardProps) => {
  const { className, text, variant = AdditionallyCardVariant.primary, onClick } = props;

  return (
    <div className={clsx(s.AdditionallyCard, s[`AdditionallyCard_${variant}`], className)} onClick={onClick}>
      <p className={s.AdditionallyCard__text}>{text}</p>
    </div>
  );
});

AdditionallyCard.displayName = 'AdditionallyCard';
