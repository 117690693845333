import { useIntl } from 'react-intl';
import { Button, ButtonVariant } from 'components/Button';
import { ReactComponent as ArrowIcon } from './arrow-icon.svg';
import s from './LanguageTooltip.module.scss';

export interface LanguageTooltipProps {
  setVisible: (visible: boolean) => void;
  changeLanguage: () => void;
}

export function LanguageTooltip({ setVisible, changeLanguage }: LanguageTooltipProps) {
  const intl = useIntl();

  const title = intl.formatMessage({ defaultMessage: 'Выбран верный язык?' });
  const acceptButtonText = intl.formatMessage({ defaultMessage: 'Да, верно' });
  const changeButtonText = intl.formatMessage({ defaultMessage: 'Изменить' });

  const handlerVisible = () => {
    setVisible(false);
  };
  const handlerChangeLanguage = () => {
    setVisible(false);
    changeLanguage();
  };

  return (
    <div className={s.LanguageTooltip}>
      <ArrowIcon className={s.LanguageTooltip__arrow} />
      <p className={s.LanguageTooltip__title}>{title}</p>
      <div className={s.LanguageTooltip__actions}>
        <Button onClick={handlerVisible}>{acceptButtonText}</Button>
        <Button variant={ButtonVariant.Tertiary} onClick={handlerChangeLanguage}>
          {changeButtonText}
        </Button>
      </div>
    </div>
  );
}
