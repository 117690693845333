import React, { useEffect, useState } from 'react';
import { Button } from 'components/Button';
import s from './Cookie.module.scss';

interface CookieProps {
  title: string;
  description: string;
  textButton: string;
}
export function Cookie({ title, description, textButton }: CookieProps) {
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    const cookieAccepted = localStorage.getItem('cookieAccept');
    if (!cookieAccepted) {
      setShow(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieAccept', 'true');
    setShow(false);
  };

  if (!show) {
    return null;
  }
  return (
    <div className={s.Cookie}>
      <h2 className={s.Cookie__tile}>{title}</h2>
      <p className={s.Cookie__description}>{description}</p>
      <Button className={s.Cookie__accept} onClick={handleAccept}>
        {textButton}
      </Button>
    </div>
  );
}
