import { Link } from 'react-router-dom';
import { ComponentNavigationLink, LinkType } from 'store/graphql-strapi';
import { PathBuilder } from 'routes/PathBuilder';
import { ReactFCC } from 'utils/helperTypes';

export interface NavigationLinkProps {
  className?: string;
  link?: ComponentNavigationLink | null;
  assetUrl?: string | null;
}

export const NavigationLink: ReactFCC<NavigationLinkProps> = (props) => {
  const { className, link, assetUrl, children } = props;

  if (!assetUrl && !link) {
    return <>{children}</>;
  }

  const isExternalLink = link?.type === LinkType.ExternalLink;

  return (
    <Link
      className={className}
      download={!!assetUrl}
      to={assetUrl ?? (link ? PathBuilder.getPathByPageType(link.type, link.uri) : '')}
      target={assetUrl || isExternalLink ? '_blank' : undefined}
      rel={isExternalLink ? 'noopener noreferrer' : undefined}>
      {children}
    </Link>
  );
};
