import { memo, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { ReactComponent as Logo } from 'assets/images/Logo.svg';
import { ReactComponent as LogoWithoutText } from 'assets/images/LogoWithoutText.svg';
import { useIsDesktopMedium, useIsMobile } from 'hooks/useBreakpoints';
import { MAIN_PAGE_ROUTE } from 'routes/routes';
import { ComponentLayoutMenuCategory } from 'store/graphql-strapi';
import { notEmpty } from 'utils/notEmpty';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { useDebounceCallback } from 'hooks/useDebounceCallback';
import { MenuItem } from './MenuItem';
import { MenuKebab } from './MenuKebab';
import s from './MenuItemsLeft.module.scss';

export interface MenuItemsLeftProps {
  className?: string;
  menuOptions?: ComponentLayoutMenuCategory[];
}

export const MenuItemsLeft = memo((props: MenuItemsLeftProps) => {
  const { className, menuOptions: initialMenuOptions } = props;

  const [menuIsReady, setMenuIsReady] = useState(false);
  const [menuOptions, setMenuOptions] = useState(initialMenuOptions);
  const [hiddenMenuOptions, setHiddenMenuOptions] = useState<ComponentLayoutMenuCategory[]>([]);
  const [menuItemOpen, setMenuItemOpen] = useState<string | null>(null);

  const navigate = useNavigate();

  const onMenuItemClick = useCallback(
    (title: string | null) => {
      title === menuItemOpen ? setMenuItemOpen(null) : setMenuItemOpen(title);
    },
    [menuItemOpen]
  );

  const isDesktopMedium = useIsDesktopMedium();
  const isMobile = useIsMobile();
  const isDesktopSmallOrTablet = !isDesktopMedium && !isMobile;

  const onResize = useDebounceCallback((target: HTMLDivElement) => {
    if (!optionsWrapperRef.current || !target) {
      return;
    }

    if (optionsWrapperRef.current.clientWidth < target.clientWidth) {
      setMenuOptions((prevOptions) => {
        if (!prevOptions || prevOptions.length === 0) {
          setMenuIsReady(true);
          return prevOptions;
        }

        const copyOptions = [...prevOptions];
        const hiddenOption = copyOptions.splice(-1)[0];

        setHiddenMenuOptions((prevHiddenOptions) =>
          prevHiddenOptions.find(({ id }) => id === hiddenOption.id)
            ? prevHiddenOptions
            : [hiddenOption, ...prevHiddenOptions]
        );

        return copyOptions;
      });
    } else {
      setMenuIsReady(true);
    }
  }, 0);

  const optionsWrapperRef = useRef<HTMLDivElement>(null);
  const optionsRef = useResizeObserver(isDesktopMedium ? onResize : undefined);

  const onLogoClick = () => {
    navigate(MAIN_PAGE_ROUTE);
  };

  return (
    <div className={clsx(s.MenuItemsLeft, className)}>
      {isDesktopMedium && (
        <>
          <LogoWithoutText
            className={clsx(s.MenuItemsLeft__logo, s.MenuItemsLeft__logo_desktopMedium)}
            onClick={onLogoClick}
          />
          <div
            ref={optionsWrapperRef}
            className={clsx(s.MenuItemsLeft__optionsWrapper, menuIsReady && s.MenuItemsLeft__optionsWrapper_ready)}>
            <div ref={optionsRef} className={s.MenuItemsLeft__options}>
              {menuOptions?.map(
                ({ id, title, menuItems }) =>
                  menuItems && (
                    <MenuItem
                      key={id}
                      text={title}
                      isOpen={menuItemOpen === title}
                      onMenuItemClick={onMenuItemClick}
                      menuItemOptions={menuItems.filter(notEmpty)}
                    />
                  )
              )}
              {hiddenMenuOptions.length > 0 && <MenuKebab options={hiddenMenuOptions} />}
            </div>
          </div>
        </>
      )}
      {isDesktopSmallOrTablet && <Logo className={s.MenuItemsLeft__logo} onClick={onLogoClick} />}
      {isMobile && <LogoWithoutText className={s.MenuItemsLeft__logo} onClick={onLogoClick} />}
    </div>
  );
});

MenuItemsLeft.displayName = 'MenuItemsLeft';
