import React, { memo } from 'react';
import { Loader, LoaderSize } from '../Loader';
import s from './FullScreenLoader.module.scss';

export const FullScreenLoader = memo(() => {
  return (
    <div className={s.FullScreenLoader}>
      <Loader size={LoaderSize.large} />
    </div>
  );
});

FullScreenLoader.displayName = 'FullScreenLoader';
