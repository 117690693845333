import { createContext } from 'react';
import { MeiliSearch } from 'meilisearch';

export interface ISearchContext {
  meilisearchClient: MeiliSearch | null;
  loading: boolean;
  error: Error | null;
}

export const SearchContext = createContext<ISearchContext>({
  meilisearchClient: null,
  loading: false,
  error: null
});
