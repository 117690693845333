import { memo } from 'react';
import clsx from 'clsx';
import s from './Divider.module.scss';

export interface DividerProps {
  className?: string;
}

export const Divider = memo(({ className }: DividerProps) => {
  return <div className={clsx(s.Divider, className)}></div>;
});

Divider.displayName = 'Divider';
