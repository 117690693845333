import { memo, useCallback, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Options } from '@popperjs/core';
import { Icon, IconSize, IconsTypes } from 'components/Icon';
import { DropdownMenu, DropdownMenuItem } from 'components/DropdownMenu';
import { ComponentNavigationMenuItem } from 'store/graphql-strapi';
import { Portal } from 'components/Portal';
import { NavigationLink } from 'features/NavigationLink';
import s from './MenuKebabItem.module.scss';

export interface MenuKebabItemProps {
  text: string;
  isOpen?: boolean;
  onMenuItemClick?: (title: string) => void;
  onOptionClick: () => void;
  menuItemOptions: ComponentNavigationMenuItem[];
}

export const popperOptions: Partial<Options> = {
  strategy: 'fixed',
  placement: 'right-start',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [42, -14]
      }
    },
    {
      name: 'preventOverflow',
      options: {
        rootBoundary: 'viewport',
        tether: false,
        altAxis: true
      }
    },
    {
      name: 'eventListeners',
      options: {
        scroll: false
      }
    }
  ]
};

export const MenuKebabItem = memo((props: MenuKebabItemProps) => {
  const { text, isOpen, onMenuItemClick, onOptionClick: onOptionClickProp, menuItemOptions } = props;

  const root = useRef<HTMLDivElement>(null);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(root.current, popperElement, {
    ...popperOptions
  });

  const onClickHandler = useCallback(() => {
    onMenuItemClick?.(text);
  }, [onMenuItemClick, text]);

  const onOptionClick = useCallback(() => {
    onOptionClickProp();
  }, [onOptionClickProp]);

  return (
    <>
      <div onClick={onClickHandler} ref={root}>
        <DropdownMenuItem className={s.MenuKebabItem} isActive={isOpen}>
          <p>{text}</p>
          <Icon icon={IconsTypes.ARROW_CHEVRON_RIGHT} size={IconSize.Small} />
        </DropdownMenuItem>
      </div>
      {isOpen && (
        <Portal>
          <div
            ref={setPopperElement}
            className={s.MenuKebabItem__dropdown}
            style={{
              ...styles.popper
            }}
            onClick={(e) => e.stopPropagation()}
            {...attributes.popper}>
            <DropdownMenu ref={dropdownMenuRef}>
              {menuItemOptions?.map(({ id, title, link }) => (
                <NavigationLink key={id} link={link}>
                  <DropdownMenuItem onClick={onOptionClick}>{title}</DropdownMenuItem>
                </NavigationLink>
              ))}
            </DropdownMenu>
          </div>
        </Portal>
      )}
    </>
  );
});

MenuKebabItem.displayName = 'MenuKebabItem';
