import { mediaQuery, useMediaQueryResult } from './useMediaQueryResult';

export const useIsMobile = () => useMediaQueryResult(mediaQuery.tabletDown);

export const useIsDesktop = () => useMediaQueryResult(mediaQuery.desktopSmallUp);

export const useIsDesktopMedium = () => useMediaQueryResult(mediaQuery.desktopMediumUp);

export const useIsDesktopLarge = () => useMediaQueryResult(mediaQuery.desktopLargeUp);

export const useIsTablet = () => {
  const isTabletUp = useMediaQueryResult(mediaQuery.tabletUp);
  const isDesktopDown = useMediaQueryResult(mediaQuery.desktopSmallDown);

  return isTabletUp && isDesktopDown;
};
