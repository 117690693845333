import { memo } from 'react';
import clsx from 'clsx';
import { Icon, IconsTypes } from 'components/Icon';
import s from './Loader.module.scss';

export enum LoaderSize {
  /**
   * Размер лоадера 20х20
   */
  small = 'small',
  /**
   * Размер лоадера 30х30
   */
  medium = 'medium',
  /**
   * Размер лоадера 40х40
   */
  large = 'large'
}

export interface LoaderProps {
  size?: LoaderSize;
  className?: string;
}

export const Loader = memo((props: LoaderProps) => {
  const { className, size = LoaderSize.small } = props;

  return (
    <div className={clsx(s.Loader, className)}>
      <Icon className={clsx(s.LoaderIcon, [s[`LoaderIcon_size_${size}`]])} icon={IconsTypes.LOADER} />
    </div>
  );
});

Loader.displayName = 'Loader';
