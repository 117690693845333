import { createContext } from 'react';
import { noop } from 'lodash-es';
import { ApolloError } from '@apollo/client';
import { DEFAULT_LOCALE } from './utils/constants';
import { getLocaleDisplayName } from './utils/getLocaleDisplayName';

export interface ILocale {
  layoutId: string | null;
  name: string;
  displayName: string;
}

export interface ILocaleContext {
  locale: ILocale;
  loading: boolean;
  error?: ApolloError;
  availableLocales: ILocale[];
  setLocale: (locale: ILocale) => void;
  visibleLanguageTooltip: boolean;
  languageTooltipHandler: (visible: boolean) => void;
}

export const initialLocale = {
  name: DEFAULT_LOCALE,
  displayName: getLocaleDisplayName(DEFAULT_LOCALE),
  layoutId: null
};

export const LocaleContext = createContext<ILocaleContext>({
  locale: initialLocale,
  loading: false,
  availableLocales: [],
  setLocale: noop,
  visibleLanguageTooltip: false,
  languageTooltipHandler: noop
});
