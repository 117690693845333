import React, { useEffect } from 'react';

export type UseClickOutsideCallback = (e: MouseEvent) => void;
export type Optional<T> = T | null | undefined;

/**
 * Вызывает callback при клике за пределы элемента обозначенного рефом ref.
 *
 * @param ref - реф на элемент, определяющий наружность клика
 * @param callback - функция, которая вызовется при клике снаружи элемента
 */
export function useClickOutside(ref: React.RefObject<Element>, callback: Optional<UseClickOutsideCallback>) {
  useEffect(() => {
    if (!callback) return;

    const handleClick = (e: MouseEvent) => {
      const element = ref.current;
      const target = e.target as Node;
      if (
        element &&
        element !== target &&
        (e.composedPath ? !e.composedPath().includes(element) : !element.contains(target))
      ) {
        callback(e);
      }
    };

    let active = true;

    setTimeout(() => {
      if (active) {
        window.addEventListener('click', handleClick);
      }
    }, 0);

    return () => {
      active = false;
      window.removeEventListener('click', handleClick);
    };
  }, [ref, callback]);
}
