import { ApolloClient, InMemoryCache } from '@apollo/client';
import { SERVICE_API_URL, STRAPI_API_URL } from 'config';

export const strapiApolloClient = new ApolloClient({
  uri: STRAPI_API_URL,
  cache: new InMemoryCache({
    typePolicies: {
      GalleryPage: {
        keyFields: ['slug']
      },
      MediaPage: {
        keyFields: ['slug']
      },
      NewsListPage: {
        keyFields: ['slug']
      },
      NewsPage: {
        keyFields: ['slug']
      },
      UploadFile: {
        keyFields: ['url']
      },
      NewsPageEntityResponseCollection: {
        keyFields: [],
        fields: {
          data: {
            keyArgs: ['$slug', '$locale', '$asideNews', '$excludeSlug'],
            merge(existing, incoming, { variables }) {
              const offset = ((variables?.page ?? 1) - 1) * (variables?.pageSize ?? 1);

              const merged = existing ? existing.slice(0) : [];
              for (let i = 0; i < incoming.length; ++i) {
                merged[offset + i] = incoming[i];
              }
              return merged;
            }
          }
        }
      }
    },
    possibleTypes: {
      InfoPageContentDynamicZone: [
        'ComponentContentMarkdown',
        'ComponentContentOnSurface',
        'ComponentContentImportantInfo',
        'ComponentContentSteps',
        'ComponentContentAccordion',
        'ComponentContentLinks',
        'ComponentContentAdvantages',
        'ComponentContentMap',
        'ComponentFeedbackFeedbackForm',
        'ComponentMediaSelfHostedVideo'
      ]
    }
  })
});

export const serviceApolloClient = new ApolloClient({
  uri: SERVICE_API_URL,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only'
    }
  }
});
