import { forwardRef, ReactNode, Ref } from 'react';
import clsx from 'clsx';
import s from './DropdownMenu.module.scss';

export interface DropdownMenuClasses {
  root?: string;
  items?: string;
  header?: string;
}

export interface DropdownMenuProps {
  /**
   * Слот для списка значений
   */
  children?: ReactNode;
  /**
   * Слот для хедера
   */
  header?: ReactNode;
  /**
   * Реф на элемент списка значений
   */
  itemsRef?: Ref<HTMLDivElement>;
  className?: string;
  classes?: DropdownMenuClasses;
}

export const DropdownMenu = forwardRef<HTMLDivElement, DropdownMenuProps>((props, ref) => {
  const { classes, className, children, itemsRef, header } = props;

  return (
    <div ref={ref} className={clsx(s.DropdownMenu, className, classes?.root)}>
      {header && <div className={clsx(s.DropdownMenu__header, classes?.header)}>{header}</div>}
      {children && (
        <div ref={itemsRef} className={clsx(s.DropdownMenu__items, classes?.items)}>
          {children}
        </div>
      )}
    </div>
  );
});

DropdownMenu.displayName = 'DropdownMenu';
