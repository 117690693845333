import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

/**
 * Задача хука следить за query параметром 'lang'(Обновлять его в случае когда он есть, подкидывать его если нет)
 */
export const useUpdateLangQueryParam = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return useCallback(
    (newLocale: string) => {
      searchParams.set('lang', newLocale);
      navigate(`?${searchParams.toString()}`);
    },
    [navigate, searchParams]
  );
};
