import React from 'react';
import ReactMarkdown from 'react-markdown';
import { usePolicyPageQuery } from 'store/graphql-strapi';
import { QueryState } from 'components/QueryState';
import { useLocale } from 'store/locale';
import s from './PrivacyPolicyPage.module.scss';

export const PrivacyPolicyPage = () => {
  const { locale } = useLocale();
  const { data, error, loading } = usePolicyPageQuery({
    variables: {
      locale: locale.layoutId
    }
  });

  const documentPage = data?.privacyPolicyPage?.data?.attributes?.documentPage?.data?.attributes;
  const id = data?.privacyPolicyPage?.data?.id;

  const contentPage = documentPage?.content ? documentPage.content : '';

  return (
    <QueryState loading={loading} error={!!error} notFound={!id || !documentPage}>
      <div className={s.PrivacyPolicyPage}>
        <h1>{documentPage?.title}</h1>
        <ReactMarkdown>{contentPage}</ReactMarkdown>
      </div>
    </QueryState>
  );
};
