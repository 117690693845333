import { useCallback, useState } from 'react';
import { useGetMyLocaleIpLazyQuery } from 'store/graphql-service';
import { serviceApolloClient } from 'providers/apollo';

export function useGetMyLocaleIp() {
  const [getLocaleIp] = useGetMyLocaleIpLazyQuery({
    client: serviceApolloClient
  });
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleGetLocaleIp = useCallback(async (): Promise<string | undefined> => {
    setLoading(true);
    try {
      const response = await getLocaleIp();
      if (response.data) {
        return response.data.getMyLocation.suggestedLanguage;
      } else {
        setError('Не удалось получить IP');
      }
      if (response.error) {
        console.error('useGetMyLocaleIp', response.error.message);
        setError(response.error.message);
      }
    } catch (err: any) {
      console.error('useGetMyLocaleIp', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [getLocaleIp]);

  return {
    error,
    loading,
    handleGetLocaleIp
  };
}
