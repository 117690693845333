import React, { ElementType, MouseEventHandler } from 'react';
import clsx from 'clsx';
import { WrapperComponentProps } from 'utils/helperTypes/WrapperComponentProps';
import { Icon, IconSize, IconsTypes } from 'components/Icon';
import { Loader, LoaderSize } from 'components/Loader';
import s from './Button.module.scss';

export enum ButtonVariant {
  PrimaryRed = 'primaryRed',
  PrimaryBlue = 'primaryBlue',
  Secondary = 'secondary',
  Tertiary = 'tertiary'
}

export interface ButtonProps<ComponentType extends ElementType = 'button'> {
  onClick?: MouseEventHandler<ComponentType>;
  variant?: ButtonVariant;
  iconLeft?: IconsTypes;
  iconRight?: IconsTypes;
  iconSize?: IconSize;
  isLoading?: boolean;
}

export const Button = <ComponentType extends ElementType = 'button'>({
  className,
  children,
  Component = 'button',
  disabled,
  variant = ButtonVariant.PrimaryRed,
  iconRight,
  iconLeft,
  onClick,
  isLoading,
  iconSize = IconSize.Medium,
  ...componentProps
}: WrapperComponentProps<ComponentType, ButtonProps<ComponentType>>) => {
  return (
    <Component
      className={clsx(s.Button, s[`Button_${variant}`], { [s.Button_disabled]: disabled || isLoading }, className)}
      onClick={onClick}
      disabled={disabled}
      {...(componentProps?.href && { target: '_blank', rel: 'noopener noreferrer' })}
      {...componentProps}>
      {iconLeft && <Icon icon={iconLeft} size={iconSize} />}
      <span>{children}</span>
      {iconRight && <Icon icon={iconRight} size={iconSize} />}
      {isLoading && <Loader className={s.Button__loader} size={LoaderSize.medium} />}
    </Component>
  );
};
