import { memo } from 'react';
import clsx from 'clsx';
import { Button, ButtonVariant } from 'components/Button';
import { IconSize, IconsTypes } from 'components/Icon';
import { useToggle } from 'hooks/useToggle';
import { ComponentLayoutMenuCategory } from 'store/graphql-strapi';
import { Divider } from 'components/Divider';
import { DropdownMenuItem } from 'components/DropdownMenu';
import { NavigationLink } from 'features/NavigationLink';
import s from './BurgerMenuItem.module.scss';

export interface BurgerMenuItemProps {
  menuOption: ComponentLayoutMenuCategory;
  closeBurgerMenu: () => void;
}

export const BurgerMenuItem = memo((props: BurgerMenuItemProps) => {
  const { menuOption, closeBurgerMenu } = props;
  const [isOpen, { toggle }] = useToggle();

  return (
    <div className={s.BurgerMenuItem}>
      <Button
        className={clsx(s.BurgerMenuItem__button, { [s.BurgerMenuItem__button_opened]: isOpen })}
        onClick={toggle}
        variant={ButtonVariant.Tertiary}
        iconSize={IconSize.Small}
        iconRight={isOpen ? IconsTypes.ARROW_CHEVRON_UP : IconsTypes.ARROW_CHEVRON_DOWN}>
        {menuOption.title}
      </Button>
      {isOpen &&
        menuOption.menuItems &&
        menuOption.menuItems.map(
          (item) =>
            item && (
              <NavigationLink key={item.id} link={item.link}>
                <DropdownMenuItem className={s.BurgerMenuItem__item} onClick={closeBurgerMenu}>
                  {item.title}
                </DropdownMenuItem>
              </NavigationLink>
            )
        )}
      <Divider className={s.BurgerMenuItem__divider} />
    </div>
  );
});

BurgerMenuItem.displayName = 'BurgerMenuItem';
