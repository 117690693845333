import React, { ElementType, MouseEventHandler } from 'react';
import clsx from 'clsx';
import { WrapperComponentProps } from 'utils/helperTypes';
import { IconSize, IconsTypes, Icon } from 'components/Icon';
import s from './IconButton.module.scss';

export enum IconButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary'
}

export interface IconButtonProps<ComponentType extends ElementType = 'button'> {
  onClick?: MouseEventHandler<ComponentType>;
  variant?: IconButtonVariant;
  iconSize?: IconSize;
  icon: IconsTypes;
}

export const IconButton = <ComponentType extends ElementType = 'button'>({
  className,
  Component = 'button',
  disabled,
  variant = IconButtonVariant.Primary,
  iconSize = IconSize.Medium,
  icon,
  onClick,
  ...rest
}: WrapperComponentProps<ComponentType, IconButtonProps<ComponentType>>) => {
  return (
    <Component
      className={clsx(s.IconButton, s[`IconButton_${variant}`], { [s.IconButton_disabled]: disabled }, className)}
      onClick={onClick}
      {...(rest?.href && { target: '_blank', rel: 'noopener noreferrer' })}
      {...rest}>
      <Icon icon={icon} size={iconSize} />
    </Component>
  );
};
