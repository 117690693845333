import { useMemo, useState, useEffect } from 'react';

export const breakpointTablet = 768;
export const breakpointDesktopSmall = 1024;
export const breakpointDesktopMedium = 1440;
export const breakpointDesktopLarge = 1920;

export const mediaQuery = {
  tabletDown: `(max-width: ${breakpointTablet - 1}px)`, // 767
  tabletUp: `(min-width: ${breakpointTablet}px)`, // 768
  desktopSmallDown: `(max-width: ${breakpointDesktopSmall - 1}px)`, // 1023
  desktopSmallUp: `(min-width: ${breakpointDesktopSmall}px)`, // 1024
  desktopMediumDown: `(max-width: ${breakpointDesktopMedium - 1}px)`, // 1439
  desktopMediumUp: `(min-width: ${breakpointDesktopMedium}px)`, // 1440
  desktopLargeDown: `(max-width: ${breakpointDesktopLarge - 1}px)`, // 1919
  desktopLargeUp: `(min-width: ${breakpointDesktopLarge}px)` // 1920
};

export function useMediaQueryResult(mediaQueryString: string) {
  const mediaQueryList = useMemo(() => {
    return window.matchMedia(mediaQueryString);
  }, [mediaQueryString]);

  const [queryResult, setQueryResult] = useState(mediaQueryList.matches);

  useEffect(() => {
    const handleMediaQueryListChange = (e: MediaQueryListEvent) => setQueryResult(e.matches);

    mediaQueryList.addEventListener('change', handleMediaQueryListChange);
    return () => mediaQueryList.removeEventListener('change', handleMediaQueryListChange);
  }, [mediaQueryList]);

  return queryResult;
}
