import React, { InputHTMLAttributes } from 'react';
import clsx from 'clsx';
import { ICONS_MAP, IconsTypes } from './Icons';
import s from './Icon.module.scss';

export enum IconSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small'
}

export interface IconProps extends Omit<InputHTMLAttributes<HTMLDivElement>, 'size'> {
  size?: IconSize;
  icon: IconsTypes;
  className?: string;
}

export function Icon({ size = IconSize.Medium, icon, className, onClick, ...otherProps }: IconProps) {
  return (
    <div
      className={clsx(s.Icon, s[`Icon_${size}`], { [s.Icon__clickable]: onClick }, className)}
      onClick={onClick}
      {...otherProps}>
      {ICONS_MAP[icon]}
    </div>
  );
}
