import React from 'react';
import { Outlet } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { FullScreenLoader, PageLoader } from 'components/Loader';
import { ErrorPage } from 'routes/error';
import { useLayout } from 'store/layout';
import { Cookie } from 'features/Cookie';
import { Header } from './Header';
import { Footer } from './Footer';
import s from './BaseLayout.module.scss';

export const BaseLayout = () => {
  const { layout, loading, error } = useLayout();

  const intl = useIntl();

  if (loading) {
    return <FullScreenLoader />;
  }

  return (
    <div className={s.BaseLayout}>
      {layout && !error ? (
        <>
          <Header />
          <React.Suspense fallback={<PageLoader className={s.BaseLayout__loader} />}>
            <main className={s.BaseLayout__main}>
              <Outlet />
            </main>
          </React.Suspense>
          <Footer className={s.BaseLayout__footer} />
          <Cookie
            title={intl.formatMessage({ defaultMessage: 'На сайте используются файлы Cookie' })}
            description={intl.formatMessage({
              defaultMessage:
                'Мы используем cookie для сбора статистики и аналитики. Это помогает нам улучшить ваш опыт и обеспечить безопасность'
            })}
            textButton={intl.formatMessage({ defaultMessage: 'Подтвердить' })}
          />
        </>
      ) : (
        <ErrorPage />
      )}
    </div>
  );
};
