import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useLayout } from 'store/layout';
import { notEmpty } from 'utils/notEmpty';
import { FooterLinksCard } from './FooterLinksCard';
import { SocialButton } from './SocialButton';
import { ReactComponent as GooglePlayBadge } from './GooglePlay-badge.svg';
import s from './TopFooter.module.scss';

export const TopFooter = memo(() => {
  const { layout } = useLayout();

  const options = useMemo(() => layout?.menuCategories.filter(notEmpty), [layout?.menuCategories]);

  if (!layout) {
    return null;
  }

  const socialLinks = layout.socialLinks?.filter(notEmpty);

  return (
    <div className={s.TopFooter}>
      {options?.map((opt) => (
        <FooterLinksCard key={opt.id} className={s.TopFooter__column} options={opt} />
      ))}
      <div
        className={clsx(s.TopFooter__column, s.TopFooter__column_social)}
        style={{ gridRow: `1 / span ${Math.ceil((options?.length ?? 1) / 3)}` }}>
        {socialLinks && socialLinks.length > 0 && (
          <div className={s.TopFooter__social}>
            <p className={s.TopFooter__category}>
              <FormattedMessage defaultMessage={'Мы в социальных сетях'} />
            </p>
            <div className={s.TopFooter__socialIcons}>
              {socialLinks.map(({ id, icon, title, url }) => (
                <SocialButton key={id} title={title} icon={icon.data?.attributes?.url} url={url} />
              ))}
            </div>
          </div>
        )}
        <div className={s.TopFooter__app}>
          <p className={s.TopFooter__category}>
            <FormattedMessage defaultMessage={'Мобильное приложение'} />
          </p>
          <Link
            to={'https://play.google.com/store/apps/details?id=mmc63.ru'}
            target={'_blank'}
            rel={'noopener noreferrer'}>
            <GooglePlayBadge className={s.TopFooter__badge} />
          </Link>
        </div>
      </div>
    </div>
  );
});

TopFooter.displayName = 'TopFooter';
