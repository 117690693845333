import React, { ChangeEvent, InputHTMLAttributes, useCallback, useRef, useState } from 'react';
import MaskedInput from 'react-text-mask';
import composeRefs from '@seznam/compose-react-refs';
import clsx from 'clsx';
import { Icon, IconSize, IconsTypes } from 'components/Icon';
import s from './Input.module.scss';

export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  title?: string;
  withHit?: boolean;
  fill?: string;
  description?: string;
  descriptionIcon?: IconsTypes;
  onChange?: (value: string) => void;
  value?: string;
  isPhone?: boolean;
  showClearIcon?: boolean;
  leftIcon?: IconsTypes;
  leftIconSize?: IconSize;
  istextarea?: string;
  inputRef?: React.Ref<HTMLInputElement | HTMLTextAreaElement>;
  maxLength?: number;
  requiredError?: boolean;
  requiredProps?: boolean;
  classes?: {
    leftIcon?: string;
    rightIcon?: string;
    title?: string;
    description?: string;
    descriptionIcon?: string;
    input?: string;
    textarea?: string;
  };
}

export const Input = (props: InputProps) => {
  const {
    description,
    descriptionIcon,
    title,
    fill,
    withHit,
    isPhone,
    istextarea,
    requiredError,
    requiredProps,
    maxLength = 500,
    showClearIcon = true,
    onChange,
    className,
    value,
    leftIcon,
    leftIconSize = IconSize.Medium,
    classes,
    disabled,
    inputRef: inputRefProp,
    ...inputProps
  } = props;

  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

  const onClearIconClick = useCallback(() => {
    onChange?.('');
    inputRef.current?.focus();
  }, [onChange]);

  const onChangeInput = useCallback(
    (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange?.(evt.target.value);
    },
    [onChange]
  );

  const [isInputActive, setIsInputActive] = useState(false);

  const onFocus = useCallback(() => {
    setIsInputActive(true);
  }, []);
  const onBlur = useCallback(() => {
    setIsInputActive(false);
  }, []);

  return (
    <div className={clsx(s.Input, className)} onFocus={onFocus} onBlur={onBlur}>
      {Boolean(title) && <span className={clsx(s.Input__title, classes?.title)}>{title}</span>}

      <div
        style={{ background: `${fill}` }}
        className={clsx(
          s.Input__inputContainer,
          istextarea === 'true' && s.Input__inputContainer_textarea,
          {
            [s.Input__inputContainer_active]: isInputActive,
            [s.Input_disabled]: disabled
          },
          requiredError && requiredProps && !value && s.Input__inputContainer_error
        )}>
        {leftIcon && (
          <Icon
            className={clsx(s.Input__leftIcon, classes?.leftIcon, { [s.Input_disabled]: disabled })}
            icon={leftIcon}
            size={leftIconSize}
          />
        )}
        {istextarea === 'true' ? (
          <textarea
            ref={composeRefs(
              inputRef as React.Ref<HTMLTextAreaElement>,
              inputRefProp as React.Ref<HTMLTextAreaElement>
            )}
            value={value}
            onChange={onChangeInput}
            disabled={disabled}
            maxLength={maxLength}
            className={clsx(s.Input__textarea, classes?.textarea, { [s.Input_disabled]: disabled })}
          />
        ) : (
          <>
            {!isPhone && (
              <input
                maxLength={maxLength}
                ref={composeRefs(inputRef as React.Ref<HTMLInputElement>, inputRefProp as React.Ref<HTMLInputElement>)}
                className={clsx(s.Input__input, classes?.input, { [s.Input_disabled]: disabled })}
                onChange={onChangeInput}
                value={value}
                disabled={disabled}
                {...inputProps}
              />
            )}
          </>
        )}
        {isPhone && (
          <MaskedInput
            className={clsx(s.Input__input, classes?.input, { [s.Input_disabled]: disabled })}
            mask={['+', '7', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
            placeholder="+7 (___) ___-__-__"
            guide={false}
            onChange={onChangeInput}
            value={value}
            disabled={disabled}
            {...inputProps}
          />
        )}
        {Boolean(value) && showClearIcon && (
          <Icon
            className={clsx(
              s.Input__clearIcon,
              classes?.rightIcon,
              { [s.Input_disabled]: disabled },
              withHit && s.Input__clearIcon_hit
            )}
            icon={IconsTypes.CROSS}
            size={IconSize.Small}
            onClick={!disabled ? onClearIconClick : undefined}
            onMouseDown={(e) => e.preventDefault()}
          />
        )}
        {istextarea === 'true' && (
          <div className={s.Input__textarea__counter}>
            {value?.length}/{maxLength}
          </div>
        )}
      </div>

      {Boolean(description) && (
        <div className={clsx(s.Input__description, { [s.Input_disabled]: disabled })}>
          {descriptionIcon && (
            <Icon className={clsx(classes?.descriptionIcon, { [s.Input_disabled]: disabled })} icon={descriptionIcon} />
          )}
          <span className={clsx(s.Input__description, classes?.description, { [s.Input_disabled]: disabled })}>
            {description}
          </span>
        </div>
      )}
    </div>
  );
};
