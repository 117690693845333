import { createContext } from 'react';
import { ApolloError } from '@apollo/client';
import { Layout, LinkType } from 'store/graphql-strapi';

export interface ILayoutContext {
  layout?: Layout | null;
  routes: {
    [K in LinkType]?: string;
  };
  defaultDescription?: string | null;
  loading: boolean;
  error?: ApolloError;
}

export const LayoutContext = createContext<ILayoutContext>({
  layout: null,
  defaultDescription: null,
  routes: {},
  loading: false
});
