export const SLUG_PARAM = 'slug';
export const ID_PARAM = 'id';
export const URL_KEY_SEARCH = 's';
export const URL_KEY_PAYMENT_UUID = 'paymentUUID';

export const INFO_PAGE_PATH = '/info';
export const CONTACT_PAGE_PATH = '/contact';
export const SERVICE_PAGE_PATH = '/service';
export const DOCUMENTS_PAGE = '/documents';
export const PRIVACY_POLICY = '/privacy-policy';

export const MAIN_PAGE_ROUTE = '/';
export const INFO_PAGE_ROUTE = `${INFO_PAGE_PATH}/:${SLUG_PARAM}`;
export const CONTACT_PAGE_ROUTE = `${CONTACT_PAGE_PATH}/:${SLUG_PARAM}`;
export const SERVICE_PAGE_ROUTE = `${SERVICE_PAGE_PATH}/*`;
export const DOCUMENTS_PAGE_ROUTE = `${DOCUMENTS_PAGE}/*`;
export const PRIVACY_POLICY_PAGE_ROUTE = `${PRIVACY_POLICY}`;

export const SEARCH_PAGE_ROUTE = '/search';

export const NOT_FOUND_PAGE_ROUTE = '/404';
export const ERROR_PAGE_ROUTE = '/error';
