import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useLayout } from 'store/layout';
import { Divider } from 'components/Divider';
import { DOCUMENTS_PAGE } from 'routes/routes';
import s from './BottomFooter.module.scss';

export const BottomFooter = memo(() => {
  const { layout } = useLayout();
  const footerDocuments = layout?.footerDocuments?.data;

  if (!layout) {
    return null;
  }

  return (
    <>
      <div className={s.BottomFooter}>
        <div className={s.BottomFooter__contacts}>
          <div className={s.BottomFooter__info}>
            <a
              className={s.BottomFooter__phone}
              href={`tel:${layout.phone}`}
              target={'_blank'}
              rel="noopener noreferrer">
              {layout.phone}
            </a>
            <p className={s.BottomFooter__text}>{layout.phoneDescription}</p>
          </div>
          <div className={clsx(s.BottomFooter__info, s.BottomFooter__info_wide, s.BottomFooter__infoBottomMobile)}>
            <p className={s.BottomFooter__address}>{layout.address}</p>
            <p className={s.BottomFooter__text}>{layout.workingHours}</p>
          </div>
        </div>
        <div className={s.Boottom__content}>
          <p className={s.BottomFooter__copyright}>©{layout.copyright}</p>
          {!!footerDocuments?.length && (
            <>
              <Divider />
              <div className={s.Documents__links}>
                {footerDocuments.map((link, index) => (
                  <Link className={s.Documents__link} key={index} to={`${DOCUMENTS_PAGE}/${link.id}`}>
                    {link.attributes?.title}
                  </Link>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
});

BottomFooter.displayName = 'BottomFooter';
