import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Button } from 'components/Button';
import { MAIN_PAGE_ROUTE } from 'routes/routes';
import s from './HeroContent.module.scss';

export interface HeroContentProps {
  className?: string;
  title?: React.ReactNode;
  text?: React.ReactNode;
}
export const HeroContent: React.FC<HeroContentProps> = (props) => {
  const { className, title, text } = props;

  return (
    <div className={clsx(s.HeroContent, className)}>
      {title && <h1 className={s.HeroContent__title}>{title}</h1>}
      {text && <p className={s.HeroContent__text}>{text}</p>}

      <Button Component={Link} replace to={MAIN_PAGE_ROUTE}>
        <FormattedMessage defaultMessage={'Вернуться на главную'} />
      </Button>
    </div>
  );
};
