import { ReactFCC } from 'utils/helperTypes';
import { PageLoader } from 'components/Loader';
import { ErrorPage } from 'routes/error';
import { NotFoundPage } from 'routes/not-found';

export interface QueryStateProps {
  loading?: boolean;
  error?: boolean;
  notFound?: boolean;
}

export const QueryState: ReactFCC<QueryStateProps> = (props) => {
  const { loading, error, notFound, children } = props;

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <ErrorPage />;
  }

  if (notFound) {
    return <NotFoundPage />;
  }

  return <>{children}</>;
};
