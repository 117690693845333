import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { lazyImport } from 'utils/lazyImport';
import { useLayout } from 'store/layout';
import { LinkType } from 'store/graphql-strapi';
import {
  ERROR_PAGE_ROUTE,
  INFO_PAGE_ROUTE,
  MAIN_PAGE_ROUTE,
  CONTACT_PAGE_ROUTE,
  SERVICE_PAGE_ROUTE,
  SLUG_PARAM,
  SEARCH_PAGE_ROUTE,
  DOCUMENTS_PAGE,
  ID_PARAM,
  PRIVACY_POLICY
} from './routes';
import { BaseLayout } from './layouts';
import { useScrollTopOnRouteChange } from './hooks/useScrollTopOnRouteChange';
import { NotFoundPage } from './not-found';
import { ErrorPage } from './error';
import { PrivacyPolicyPage } from './privacy-policy/PrivacyPolicyPage';

const { MainPage } = lazyImport(() => import('./main'), 'MainPage');
const { InfoPage } = lazyImport(() => import('./info'), 'InfoPage');
const { ContactPage } = lazyImport(() => import('./contact'), 'ContactPage');
const { DocumentsPage } = lazyImport(() => import('./documents'), 'DocumentsPage');
const { ServicePage } = lazyImport(() => import('./services'), 'ServicePage');
const { GalleryPage } = lazyImport(() => import('./gallery/GalleryPage'), 'GalleryPage');
const { MediaPage } = lazyImport(() => import('./gallery/MediaPage'), 'MediaPage');
const { NewsListPage } = lazyImport(() => import('./news/NewsListPage'), 'NewsListPage');
const { NewsPage } = lazyImport(() => import('./news/NewsPage'), 'NewsPage');
const { SearchPage } = lazyImport(() => import('./search'), 'SearchPage');

function YM() {
  const { pathname } = useLocation();

  useEffect(() => {
    // Yandex.Metrika
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.ym?.('27722295', 'hit', pathname);
  }, [pathname]);

  return null;
}

export function AppRoutes() {
  const { routes } = useLayout();
  useScrollTopOnRouteChange();

  YM();

  return (
    <Routes>
      <Route element={<BaseLayout />}>
        <Route path={MAIN_PAGE_ROUTE} element={<MainPage />} />
        <Route path={INFO_PAGE_ROUTE} element={<InfoPage />} />
        <Route path={CONTACT_PAGE_ROUTE} element={<ContactPage />} />
        <Route path={SERVICE_PAGE_ROUTE} element={<ServicePage />}>
          <Route path={`:${SLUG_PARAM}`} element={<ServicePage />} />
        </Route>
        <Route path={DOCUMENTS_PAGE} element={<DocumentsPage />}>
          <Route path={`:${ID_PARAM}`} element={<DocumentsPage />} />
        </Route>

        <Route path={PRIVACY_POLICY} element={<PrivacyPolicyPage />} />

        {routes[LinkType.GalleryPage] && (
          <Route path={`/${routes[LinkType.GalleryPage]}`}>
            <Route index element={<GalleryPage />} />
            <Route path={`:${SLUG_PARAM}`} element={<MediaPage />} />
          </Route>
        )}
        {routes[LinkType.NewsListPage] && (
          <Route path={`/${routes[LinkType.NewsListPage]}`}>
            <Route index element={<NewsListPage />} />
            <Route path={`:${SLUG_PARAM}`} element={<NewsPage />} />
          </Route>
        )}

        <Route path={SEARCH_PAGE_ROUTE} element={<SearchPage />} />

        <Route path={ERROR_PAGE_ROUTE} element={<ErrorPage />} />
        <Route path={'*'} element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
}
