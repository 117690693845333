import clsx from 'clsx';
import { ReactFCC } from 'utils/helperTypes';
import s from './Hero.module.scss';

export interface HeroProps {
  className?: string;
  align?: 'center' | null;
}

export const Hero: ReactFCC<HeroProps> = ({ className, align, children }) => {
  return <div className={clsx(s.Hero, align === 'center' && s.Hero_center, className)}>{children}</div>;
};
