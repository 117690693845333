import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { MeiliSearch, Key } from 'meilisearch';
import { SEARCH_URL, STRAPI_CONFIG_API_URL } from 'config';
import { SearchContext } from './SearchContext';

export const SearchProvider = ({ children }: PropsWithChildren) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [meilisearchClient, setMeilisearchClient] = useState<MeiliSearch | null>(null);

  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    setLoading(true);
    setError(null);
    abortControllerRef.current?.abort();
    abortControllerRef.current = new AbortController();
    fetch(`${STRAPI_CONFIG_API_URL}/config/searchkey`, {
      signal: abortControllerRef.current.signal
    })
      .then((response) => response.json())
      .then((data?: Key | null) => {
        if (!data || !data.key || typeof data.key !== 'string') {
          setError(new Error('bad key returned from strapi api'));
          return;
        }

        setMeilisearchClient(
          new MeiliSearch({
            host: SEARCH_URL,
            apiKey: data.key
          })
        );
      })
      .catch((error: Error) => {
        if (typeof error === 'object' && error?.message === 'The user aborted a request.') {
          return;
        }
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <SearchContext.Provider
      value={{
        loading,
        error,
        meilisearchClient
      }}>
      {children}
    </SearchContext.Provider>
  );
};
