import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export enum ApplicationMessageEnum {
  /** Заявление не найдено. Проверьте введенные данные. */
  ApplicationNotFound = 'APPLICATION_NOT_FOUND',
  /** По Вашему заявлению была произведена оплата. Заявление находится в обработке в Миграционном центре. Проверьте статус позже. */
  ApplicationReview = 'APPLICATION_REVIEW',
  /** Ваше заявление на патент было аннулировано. За подробной информацией необходимо обратиться в Миграционный центр. */
  Cancelled = 'CANCELLED',
  /** Заявление закрыто. */
  Closed = 'CLOSED',
  /** Принято решение по патенту. По результатам рассмотрения заявления принято решение. Вам необходимо прибыть в Миграционный центр в зону выдачи патента. */
  DecisionReady = 'DECISION_READY',
  /** Патент готов к выдаче. Для получения патента Вам необходимо совершить первый платеж по НДФЛ. Его рекомендуется совершить в Миграционном центре. */
  PatentReady = 'PATENT_READY',
  /** Вам необходимо явиться в Миграционный центр и произвести оплату услуг. */
  PaymentNeeded = 'PAYMENT_NEEDED',
  /** Заявление обрабатывается ГБУ Миграционный центр. */
  ProcessingInProgress = 'PROCESSING_IN_PROGRESS',
  /** Обработка заявления на патент приостановлена. За подробной информацией необходимо обратиться в Миграционный центр сектор А4 с имеющимися документами для оформления патента. Время приема: ежедневно с 8-00 до 19-00. */
  ProcessingPaused = 'PROCESSING_PAUSED',
  /** Пакет документов передан в МВД России по городу Москве, ожидайте СМС о готовности патента. */
  TransferredToMvd = 'TRANSFERRED_TO_MVD'
}

export type ApplicationType = {
  __typename?: 'ApplicationType';
  /** Статус заявления на патент. */
  message: ApplicationMessageEnum;
  /** Номер заявления на патент. */
  number: Scalars['String']['output'];
};

export type CaptchaType = {
  __typename?: 'CaptchaType';
  /** Идентификатор действия клиента. Совпадает с переданным на входе. */
  actionId: Scalars['String']['output'];
  /** Ссылка на картинку капчи */
  url: Scalars['String']['output'];
};

export type CreatePatentInvoiceInput = {
  /** Генерируется фронтом перед выполнением `Query.getCaptcha`. Длина от 5 до 36 символов. */
  actionId?: InputMaybe<Scalars['String']['input']>;
  /** Код капчи. Длина должна быть 5 символов, регулярка для проверки: `/^[a-z0-9]+$/i`. */
  captcha?: InputMaybe<Scalars['String']['input']>;
  /**
   * Обязательное поле. Имя ИГ.
   * **Ограничения**:
   * 1) Буквы кириллицы, Дефис, Пробел, Апостроф. Регулярка: `/^[\u0410-\u044F\u0401\u0451\s\-']+$/u`;
   * 2) Размер поля - 2-256 символов.
   */
  firstName: Scalars['String']['input'];
  /**
   * Обязательное поле. Фамилия ИГ.
   * **Ограничения**:
   * 1) Буквы кириллицы, Дефис, Пробел, Апостроф. Регулярка: `/^[\u0410-\u044F\u0401\u0451\s\-']+$/u`;
   * 2) Размер поля - 2-256 символов.
   */
  lastName: Scalars['String']['input'];
  /**
   * Не обязательное поле. Отчество ИГ.
   * **Ограничения**:
   * 1) Буквы кириллицы, Дефис, Пробел, Апостроф. Регулярка: `/^[\u0410-\u044F\u0401\u0451\s\-']+$/u`;
   * 2) Размер поля: 2-256 символов.
   */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Обязательное поле. Количество месяцев для расчета суммы к оплате в квитанции. */
  monthCount?: Scalars['Int']['input'];
  /** Обязательное поле. Номер патента (8-10 цифр). Допустимые символы: `/^[0-9 -]*$/`. Тире и пробелы не учитываются и будут очищены автоматически. */
  patentNumber: Scalars['String']['input'];
  /** Обязательное поле. Серия патента. Строго 2 цифры. Пробелы не учитываются и будут очищены автоматически. */
  patentSerial: Scalars['String']['input'];
};

export type CreatePaymentUrlInput = {
  /** Генерируется фронтом перед выполнением `Query.getCaptcha`. Длина от 5 до 36 символов. */
  actionId?: InputMaybe<Scalars['String']['input']>;
  /** Код капчи. Длина должна быть 5 символов, регулярка для проверки: `/^[a-z0-9]+$/i`. */
  captcha?: InputMaybe<Scalars['String']['input']>;
  /**
   * Обязательное поле. Имя ИГ.
   * **Ограничения**:
   * 1) Буквы кириллицы, Дефис, Пробел, Апостроф. Регулярка: `/^[\u0410-\u044F\u0401\u0451\s\-']+$/u`;
   * 2) Размер поля - 2-256 символов.
   */
  firstName: Scalars['String']['input'];
  /**
   * Обязательное поле. Фамилия ИГ.
   * **Ограничения**:
   * 1) Буквы кириллицы, Дефис, Пробел, Апостроф. Регулярка: `/^[\u0410-\u044F\u0401\u0451\s\-']+$/u`;
   * 2) Размер поля - 2-256 символов.
   */
  lastName: Scalars['String']['input'];
  /**
   * Не обязательное поле. Отчество ИГ.
   * **Ограничения**:
   * 1) Буквы кириллицы, Дефис, Пробел, Апостроф. Регулярка: `/^[\u0410-\u044F\u0401\u0451\s\-']+$/u`;
   * 2) Размер поля: 2-256 символов.
   */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Обязательно поле. Количество месяцев для расчета суммы к оплате в квитанции. */
  monthCount?: Scalars['Int']['input'];
  /** Обязательно поле. Номер патента (8-10 цифр). Допустимые символы: `/^[0-9 -]*$/`. Тире и пробелы не учитываются и будут очищены автоматически. */
  patentNumber: Scalars['String']['input'];
  /** Обязательно поле. Серия патента. Строго 2 цифры. Пробелы не учитываются и будут очищены автоматически. */
  patentSerial: Scalars['String']['input'];
  /**
   * Путь должен начинаться со слэша.
   *
   * Базовый адрес фронта подставляет бэкенд, но можно добавить путь до конкретной страницы. Например, если передать `/ordering`, то колбэк ссылка будет в виде `http://baseurl.ru/ordering?foo=bar`.
   */
  urlPath?: InputMaybe<Scalars['String']['input']>;
};

export type GetApplicationInput = {
  /** Генерируется фронтом перед выполнением `Query.getCaptcha`. Длина от 5 до 36 символов. */
  actionId?: InputMaybe<Scalars['String']['input']>;
  /** Код капчи. Длина должна быть 5 символов, регулярка для проверки: `/^[a-z0-9]+$/i`. */
  captcha?: InputMaybe<Scalars['String']['input']>;
  /** Номер заявления на патент. Может быть длиной от 1 до 12 цифр. Допустимые символы: `/^[0-9 -]*$/`. Тире и пробелы не учитываются в количестве символов и будут очищены автоматически. */
  number?: InputMaybe<Scalars['String']['input']>;
};

export type GetCaptchaInput = {
  /** Рандомная строка длиной от 5 до 36 символов, используется для идентификации действия пользователя */
  actionId: Scalars['String']['input'];
};

export type GetInnByPatentNumberInput = {
  /** Генерируется фронтом перед выполнением `Query.getCaptcha`. Длина от 5 до 36 символов. */
  actionId?: InputMaybe<Scalars['String']['input']>;
  /** Код капчи. Длина должна быть 5 символов, регулярка для проверки: `/^[a-z0-9]+$/i`. */
  captcha?: InputMaybe<Scalars['String']['input']>;
  /** Номер патента (8-10 цифр). Допустимые символы: `/^[0-9 -]*$/`. Тире и пробелы не учитываются и будут очищены автоматически. */
  patentNumber: Scalars['String']['input'];
  /** Серия патента. Строго 2 цифры. Пробелы не учитываются и будут очищены автоматически. */
  patentSerial: Scalars['String']['input'];
};

export type GetPaymentStatusInput = {
  /** Обязательное поле. Уникальный идентификатор платежных реквизитов. Можно получить из callback url при возвращении с pay mos. */
  paymentUUID: Scalars['String']['input'];
};

export type HealthRes = {
  __typename?: 'HealthRes';
  basic: Scalars['Boolean']['output'];
};

export type InnType = {
  __typename?: 'InnType';
  /** Может вернуться пустая строка. Это означает, что заявление не найдено или заявление найдено, но ИНН отсутствует. */
  inn: Scalars['String']['output'];
};

export type InvoicePdfType = {
  __typename?: 'InvoicePdfType';
  /** Дата создания квитанции в формате `dd.mm.yyyy, hh:mm` */
  date: Scalars['String']['output'];
  /** Название объекта в S3 Minio. Пример: `invoice_2e2fa222-f102-43fe-9d9b-817a98240390.pdf`. */
  objectname: Scalars['String']['output'];
  /** Читабельное имя файла. Пример: `Квитанция 77-3991966.pdf`. */
  originalname: Scalars['String']['output'];
  /** Размер в мегабайтах с одним знаком после запятой. Пример: `11.4`, `0.2`. */
  size: Scalars['String']['output'];
  /** Ссылка на квитанцию */
  url: Scalars['String']['output'];
};

export type Location = {
  __typename?: 'Location';
  /**
   * 2 letter ISO-3166-1 country code (Uppercase). Check here for
   *       details: https://www.iban.com/country-codes .
   */
  countryCode: Scalars['String']['output'];
  /**
   * I18N код локализации. Используются только те локализации, которые есть на клиенте.
   *       Если определить локализацию не удалось (например, для страны, которой нет в списке),
   *       подставляется ru.
   */
  suggestedLanguage: Scalars['String']['output'];
};

export type MonthNdflSumType = {
  __typename?: 'MonthNdflSumType';
  /** Строковое представление даты. Пример: 01.01.2023 */
  changeDate: Scalars['String']['output'];
  ndflSum: Scalars['Float']['output'];
  /** Идентификаторы объектов */
  subjectId: Scalars['Int']['output'];
  /** Пример: Московская область */
  subjectName: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Сформировать квитанцию для оплаты НДФЛ */
  createPatentInvoice: PatentInvoiceType;
  /** Оплатить НДФЛ онлайн */
  createPaymentUrl: PaymentUrlType;
  createUploadUrl: Scalars['String']['output'];
  /** Сгенерировать капчу. Записывает `actionId` в память. Флоу: показать пользователю картинку и отправить пользовательский ввод в поле `captcha` для аутентификации действия пользователя. */
  getCaptcha: CaptchaType;
  sendFeedback: Scalars['Boolean']['output'];
  /** Мутация для теста ввода капчи. */
  testCaptcha: Scalars['Boolean']['output'];
};


export type MutationCreatePatentInvoiceArgs = {
  input: CreatePatentInvoiceInput;
};


export type MutationCreatePaymentUrlArgs = {
  input: CreatePaymentUrlInput;
};


export type MutationGetCaptchaArgs = {
  input: GetCaptchaInput;
};


export type MutationSendFeedbackArgs = {
  input: SendFeedbackInput;
};


export type MutationTestCaptchaArgs = {
  input: TestCaptchaInput;
};

export type PatentCheckInput = {
  /** Номер патента (8-10 цифр). Допустимые символы: `/^[0-9 -]*$/`. Тире и пробелы не учитываются и будут очищены автоматически. */
  patentNumber: Scalars['String']['input'];
  /** Серия патента. Строго 2 цифры. Пробелы не учитываются и будут очищены автоматически. */
  patentSerial: Scalars['String']['input'];
};

export type PatentCheckRes = {
  __typename?: 'PatentCheckRes';
  issueDate: Scalars['String']['output'];
  /** Значение для расчета суммы к оплате на клиенте. Перемножается на количество месяцев. */
  monthNdflSum: Scalars['Float']['output'];
  paidTillDate: Scalars['String']['output'];
  patentNumber: Scalars['String']['output'];
  patentSerial: Scalars['String']['output'];
  resultCode: ResultCodeEnum;
  status: PatentStatusCodeEnum;
  /** Идентификатор субъекта к которому относится патент (Москва, Московская область). В зависимости от этого подставляется разное значение в `monthNdflSum`. */
  subjectId: Scalars['Int']['output'];
};

/** Описания полей взяты из [документации вышестоящего сервиса ММЦ](https://docs.google.com/document/d/1Max0FyVwO7YCQBsnSEyxbL2CXi40v-VR3kM6Egaw0zQ/edit) */
export type PatentInvoiceType = {
  __typename?: 'PatentInvoiceType';
  /** Поле квитанции «Адрес». Передается пустым. */
  address?: Maybe<Scalars['String']['output']>;
  /** Поле квитанции «БИК». БИК банка получателя. */
  bik: Scalars['String']['output'];
  /** Специальная услуга Сбербанка (вид платежа). В полях квитанции не указывается. */
  category: Scalars['String']['output'];
  /** Поле квитанции «(101)» */
  field101: Scalars['String']['output'];
  /** Поле квитанции «(106)» */
  field106: Scalars['String']['output'];
  /** Поле квитанции «(107)». Передается пустым. */
  field107?: Maybe<Scalars['String']['output']>;
  /** Поле квитанции «(110)» */
  field110: Scalars['String']['output'];
  /** Поле квитанции «ФИО». Склеенное значение из Фамилии, Имени, Отчества. */
  fio: Scalars['String']['output'];
  /** Поле квитанции «ИНН». ИНН ИФНС. */
  fnsInn: Scalars['String']['output'];
  /** Поле квитанции «КПП». КПП ИФНС. */
  fnsKpp: Scalars['String']['output'];
  /** Поле квитанции «ОКТМО». ОКТМО ИФНС. */
  fnsOktmo: Scalars['String']['output'];
  /** Поле квитанции «Форма» */
  form: Scalars['String']['output'];
  /** Поле квитанции «ИНН». ИНН ИГ (плательщика). */
  inn: Scalars['String']['output'];
  /** Номер налоговой службы. В полях квитанции не указывается. */
  instNum: Scalars['String']['output'];
  /** Поле квитанции «КБК». Код бюджетной классификации. */
  kbk: Scalars['String']['output'];
  /** Фамилия ИГ. Отдельно не используется в квитанции. */
  lastName: Scalars['String']['output'];
  /** Имя ИГ. Отдельно не используется в квитанции. */
  name: Scalars['String']['output'];
  /** Трансформированный patentNumber. */
  patentNumber: Scalars['String']['output'];
  /** Трансформированный patentSerial */
  patentSerial: Scalars['String']['output'];
  pdf: InvoicePdfType;
  /** Поле квитанции «Поле, зарезервированное для линейного штрих кода». Назначение платежа. */
  purpose: Scalars['String']['output'];
  /** Двумерный штрих-код QRCODE (Base64).Располагается в верхнем левом блоке квитанции под текстом «Извещение». */
  qrCodeImage: Scalars['String']['output'];
  /** Поле квитанции «Получатель». Один из ИФНС к которому относится ИНН ИГ. */
  recipient: Scalars['String']['output'];
  /** Поле квитанции «Счет №». № счета получателя. */
  recipientAccount: Scalars['String']['output'];
  /** Поле квитанции «Банк получателя)» */
  recipientBank: Scalars['String']['output'];
  /** Поле квитанции «Счет №». № счета банка получателя. */
  recipientBankAccount: Scalars['String']['output'];
  /** Отчество ИГ. Отдельно не используется в квитанции. */
  secondName: Scalars['String']['output'];
  /** Поле квитанции «Сумма». Сумма к оплате, рассчитанная на основе текущего тарифа НДФЛ и преданного кол-ва месяцев оплаты. */
  sum: Scalars['String']['output'];
  /** Сумма платежа в копейках, использованная для формирования QR кода */
  sumKopeck: Scalars['String']['output'];
};

export enum PatentStatusCodeEnum {
  CanceledPatent = 'CANCELED_PATENT',
  InvalidPatent = 'INVALID_PATENT',
  PatentExpired = 'PATENT_EXPIRED',
  PatentIsNotPaid = 'PATENT_IS_NOT_PAID',
  PatentValid = 'PATENT_VALID'
}

export enum PaymentStatusEnum {
  /** Распоряжение на платеж выполнено */
  Aprp = 'APRP',
  /** Распоряжение на платеж отклонено */
  Decl = 'DECL',
  /** Распоряжение на оплату успешно передано в ППС */
  Post = 'POST',
  /** Распоряжение на платеж обрабатывается */
  Proc = 'PROC',
  /** Платежные реквизиты получены и зарегистрированы в ИС ЕПШ */
  Regs = 'REGS'
}

export type PaymentStatusType = {
  __typename?: 'PaymentStatusType';
  /** Наименования платежного сервиса, через который была осуществлена оплата */
  bankName: Scalars['String']['output'];
  /** Дата и время создания запроса */
  creationDate: Scalars['String']['output'];
  /** Внутренний идентификатор патента */
  patentId: Scalars['String']['output'];
  /** Дата проведения платежа */
  paymentDate: Scalars['String']['output'];
  /** Уникальный идентификатор платежных реквизитов, совпадает с указанным в запросе */
  paymentUUID: Scalars['String']['output'];
  /** Статус распоряжения на платеж. */
  status: PaymentStatusEnum;
  /** Адрес страницы ИС ЕПШ (URL) со статусом платежа} */
  statusPageUrl: Scalars['String']['output'];
  /** Сумма платежи в копейках */
  sum: Scalars['Int']['output'];
  /** УИП – уникальный идентификатор платежа, присвоенный платежным сервисом (значение необходимо для идентификации платежей) */
  systemIdentifier: Scalars['String']['output'];
};

export type PaymentUrlType = {
  __typename?: 'PaymentUrlType';
  /** Уникальный идентификатор платежных реквизитов. Ограничен 36 символами. Необходим для получения статуса оплаты. */
  paymentUUID?: Maybe<Scalars['String']['output']>;
  /** Адрес страницы ИС ЕПШ (URL) на которую необходимо переадресовать пользователя для выполнения оплаты */
  url?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /** Узнать статус заявления на патент */
  getApplication: ApplicationType;
  /** Получить ИНН по номеру заявления на патент. */
  getInnByApplicationNumber: InnType;
  /** Получить ИНН по серии и номеру патента. */
  getInnByPatentNumber: InnType;
  getMonthNdflSum: Array<MonthNdflSumType>;
  /**
   * Метод возвращает локацию пользователя по его IP адресу.
   *     В случае, если не получается определить IP адрес, выбрасывается 400 ошибка.
   *     IP адрес определяется по хедеру X-Forwarded-For или по
   *     "req.socket.remoteAddress" (см. Node.js документацию).
   */
  getMyLocation: Location;
  getPaymentStatus: PaymentStatusType;
  health: HealthRes;
  patentCheck: PatentCheckRes;
};


export type QueryGetApplicationArgs = {
  input: GetApplicationInput;
};


export type QueryGetInnByApplicationNumberArgs = {
  input: GetApplicationInput;
};


export type QueryGetInnByPatentNumberArgs = {
  input: GetInnByPatentNumberInput;
};


export type QueryGetPaymentStatusArgs = {
  input: GetPaymentStatusInput;
};


export type QueryPatentCheckArgs = {
  input: PatentCheckInput;
};

export enum ResultCodeEnum {
  PatentNotFound = 'PATENT_NOT_FOUND',
  Success = 'SUCCESS'
}

export type SendFeedbackInput = {
  /** Генерируется фронтом перед выполнением `Query.getCaptcha`. Длина от 5 до 36 символов. */
  actionId?: InputMaybe<Scalars['String']['input']>;
  /** Идентификаторы объектов в S3 хранилище. Могут быть получены при загрузке файла `POST /uploadFile/:uploadKey` в свойстве `$.obj`. */
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  body: Scalars['String']['input'];
  /** Код капчи. Длина должна быть 5 символов, регулярка для проверки: `/^[a-z0-9]+$/i`. */
  captcha?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор Типа документа. Можно передавать идентификатор записи на любом языке.Из БД также будут выбраны поля для проверки обязательности свойств `series` и `number`. */
  docTypeId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  formId: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  /** Номер документа. */
  number?: InputMaybe<Scalars['String']['input']>;
  patronymic?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Серия документа. */
  series?: InputMaybe<Scalars['String']['input']>;
  surname?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор Темы обращения. Можно передавать идентификатор записи на любом языке. */
  topicId?: InputMaybe<Scalars['Int']['input']>;
};

export type TestCaptchaInput = {
  /** Генерируется фронтом перед выполнением `Query.getCaptcha`. Длина от 5 до 36 символов. */
  actionId?: InputMaybe<Scalars['String']['input']>;
  /** Код капчи. Длина должна быть 5 символов, регулярка для проверки: `/^[a-z0-9]+$/i`. */
  captcha?: InputMaybe<Scalars['String']['input']>;
};

export type GetCaptchaMutationVariables = Exact<{
  actionId: Scalars['String']['input'];
}>;


export type GetCaptchaMutation = { __typename?: 'Mutation', getCaptcha: { __typename?: 'CaptchaType', actionId: string, url: string } };

export type SendFeedbackFormMutationVariables = Exact<{
  input: SendFeedbackInput;
}>;


export type SendFeedbackFormMutation = { __typename?: 'Mutation', sendFeedback: boolean };

export type CreateUploadFileUrlMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateUploadFileUrlMutation = { __typename?: 'Mutation', createUploadUrl: string };

export type GetInnByApplicationNumberQueryVariables = Exact<{
  number: Scalars['String']['input'];
  actionId: Scalars['String']['input'];
  captcha: Scalars['String']['input'];
}>;


export type GetInnByApplicationNumberQuery = { __typename?: 'Query', getInnByApplicationNumber: { __typename?: 'InnType', inn: string } };

export type GetInnByPatentNumberQueryVariables = Exact<{
  patentNumber: Scalars['String']['input'];
  patentSerial: Scalars['String']['input'];
  actionId: Scalars['String']['input'];
  captcha: Scalars['String']['input'];
}>;


export type GetInnByPatentNumberQuery = { __typename?: 'Query', getInnByPatentNumber: { __typename?: 'InnType', inn: string } };

export type CreatePatentInvoiceMutationVariables = Exact<{
  actionId: Scalars['String']['input'];
  captcha: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  monthCount: Scalars['Int']['input'];
  patentNumber: Scalars['String']['input'];
  patentSerial: Scalars['String']['input'];
}>;


export type CreatePatentInvoiceMutation = { __typename?: 'Mutation', createPatentInvoice: { __typename?: 'PatentInvoiceType', pdf: { __typename?: 'InvoicePdfType', url: string, date: string, originalname: string, size: string } } };

export type GetPaymentStatusQueryVariables = Exact<{
  paymentUUID: Scalars['String']['input'];
}>;


export type GetPaymentStatusQuery = { __typename?: 'Query', getPaymentStatus: { __typename?: 'PaymentStatusType', status: PaymentStatusEnum } };

export type CreatePaymentUrlMutationVariables = Exact<{
  actionId: Scalars['String']['input'];
  captcha: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  monthCount: Scalars['Int']['input'];
  patentNumber: Scalars['String']['input'];
  patentSerial: Scalars['String']['input'];
  urlPath: Scalars['String']['input'];
}>;


export type CreatePaymentUrlMutation = { __typename?: 'Mutation', createPaymentUrl: { __typename?: 'PaymentUrlType', url?: string | null } };

export type GetApplicationQueryVariables = Exact<{
  number: Scalars['String']['input'];
  actionId: Scalars['String']['input'];
  captcha: Scalars['String']['input'];
}>;


export type GetApplicationQuery = { __typename?: 'Query', getApplication: { __typename?: 'ApplicationType', message: ApplicationMessageEnum, number: string } };

export type GetMonthNdflSumQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMonthNdflSumQuery = { __typename?: 'Query', getMonthNdflSum: Array<{ __typename?: 'MonthNdflSumType', changeDate: string, ndflSum: number, subjectName: string }> };

export type PatentCheckQueryVariables = Exact<{
  patentNumber: Scalars['String']['input'];
  patentSerial: Scalars['String']['input'];
}>;


export type PatentCheckQuery = { __typename?: 'Query', patentCheck: { __typename?: 'PatentCheckRes', resultCode: ResultCodeEnum, status: PatentStatusCodeEnum, monthNdflSum: number } };

export type GetMyLocaleIpQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyLocaleIpQuery = { __typename?: 'Query', getMyLocation: { __typename?: 'Location', countryCode: string, suggestedLanguage: string } };


export const GetCaptchaDocument = gql`
    mutation GetCaptcha($actionId: String!) {
  getCaptcha(input: {actionId: $actionId}) {
    actionId
    url
  }
}
    `;
export type GetCaptchaMutationFn = Apollo.MutationFunction<GetCaptchaMutation, GetCaptchaMutationVariables>;

/**
 * __useGetCaptchaMutation__
 *
 * To run a mutation, you first call `useGetCaptchaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetCaptchaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getCaptchaMutation, { data, loading, error }] = useGetCaptchaMutation({
 *   variables: {
 *      actionId: // value for 'actionId'
 *   },
 * });
 */
export function useGetCaptchaMutation(baseOptions?: Apollo.MutationHookOptions<GetCaptchaMutation, GetCaptchaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetCaptchaMutation, GetCaptchaMutationVariables>(GetCaptchaDocument, options);
      }
export type GetCaptchaMutationHookResult = ReturnType<typeof useGetCaptchaMutation>;
export type GetCaptchaMutationResult = Apollo.MutationResult<GetCaptchaMutation>;
export type GetCaptchaMutationOptions = Apollo.BaseMutationOptions<GetCaptchaMutation, GetCaptchaMutationVariables>;
export const SendFeedbackFormDocument = gql`
    mutation SendFeedbackForm($input: SendFeedbackInput!) {
  sendFeedback(input: $input)
}
    `;
export type SendFeedbackFormMutationFn = Apollo.MutationFunction<SendFeedbackFormMutation, SendFeedbackFormMutationVariables>;

/**
 * __useSendFeedbackFormMutation__
 *
 * To run a mutation, you first call `useSendFeedbackFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendFeedbackFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendFeedbackFormMutation, { data, loading, error }] = useSendFeedbackFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendFeedbackFormMutation(baseOptions?: Apollo.MutationHookOptions<SendFeedbackFormMutation, SendFeedbackFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendFeedbackFormMutation, SendFeedbackFormMutationVariables>(SendFeedbackFormDocument, options);
      }
export type SendFeedbackFormMutationHookResult = ReturnType<typeof useSendFeedbackFormMutation>;
export type SendFeedbackFormMutationResult = Apollo.MutationResult<SendFeedbackFormMutation>;
export type SendFeedbackFormMutationOptions = Apollo.BaseMutationOptions<SendFeedbackFormMutation, SendFeedbackFormMutationVariables>;
export const CreateUploadFileUrlDocument = gql`
    mutation CreateUploadFileUrl {
  createUploadUrl
}
    `;
export type CreateUploadFileUrlMutationFn = Apollo.MutationFunction<CreateUploadFileUrlMutation, CreateUploadFileUrlMutationVariables>;

/**
 * __useCreateUploadFileUrlMutation__
 *
 * To run a mutation, you first call `useCreateUploadFileUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadFileUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadFileUrlMutation, { data, loading, error }] = useCreateUploadFileUrlMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateUploadFileUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreateUploadFileUrlMutation, CreateUploadFileUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUploadFileUrlMutation, CreateUploadFileUrlMutationVariables>(CreateUploadFileUrlDocument, options);
      }
export type CreateUploadFileUrlMutationHookResult = ReturnType<typeof useCreateUploadFileUrlMutation>;
export type CreateUploadFileUrlMutationResult = Apollo.MutationResult<CreateUploadFileUrlMutation>;
export type CreateUploadFileUrlMutationOptions = Apollo.BaseMutationOptions<CreateUploadFileUrlMutation, CreateUploadFileUrlMutationVariables>;
export const GetInnByApplicationNumberDocument = gql`
    query GetInnByApplicationNumber($number: String!, $actionId: String!, $captcha: String!) {
  getInnByApplicationNumber(
    input: {number: $number, actionId: $actionId, captcha: $captcha}
  ) {
    inn
  }
}
    `;

/**
 * __useGetInnByApplicationNumberQuery__
 *
 * To run a query within a React component, call `useGetInnByApplicationNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInnByApplicationNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInnByApplicationNumberQuery({
 *   variables: {
 *      number: // value for 'number'
 *      actionId: // value for 'actionId'
 *      captcha: // value for 'captcha'
 *   },
 * });
 */
export function useGetInnByApplicationNumberQuery(baseOptions: Apollo.QueryHookOptions<GetInnByApplicationNumberQuery, GetInnByApplicationNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInnByApplicationNumberQuery, GetInnByApplicationNumberQueryVariables>(GetInnByApplicationNumberDocument, options);
      }
export function useGetInnByApplicationNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInnByApplicationNumberQuery, GetInnByApplicationNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInnByApplicationNumberQuery, GetInnByApplicationNumberQueryVariables>(GetInnByApplicationNumberDocument, options);
        }
export type GetInnByApplicationNumberQueryHookResult = ReturnType<typeof useGetInnByApplicationNumberQuery>;
export type GetInnByApplicationNumberLazyQueryHookResult = ReturnType<typeof useGetInnByApplicationNumberLazyQuery>;
export type GetInnByApplicationNumberQueryResult = Apollo.QueryResult<GetInnByApplicationNumberQuery, GetInnByApplicationNumberQueryVariables>;
export const GetInnByPatentNumberDocument = gql`
    query GetInnByPatentNumber($patentNumber: String!, $patentSerial: String!, $actionId: String!, $captcha: String!) {
  getInnByPatentNumber(
    input: {patentNumber: $patentNumber, patentSerial: $patentSerial, actionId: $actionId, captcha: $captcha}
  ) {
    inn
  }
}
    `;

/**
 * __useGetInnByPatentNumberQuery__
 *
 * To run a query within a React component, call `useGetInnByPatentNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInnByPatentNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInnByPatentNumberQuery({
 *   variables: {
 *      patentNumber: // value for 'patentNumber'
 *      patentSerial: // value for 'patentSerial'
 *      actionId: // value for 'actionId'
 *      captcha: // value for 'captcha'
 *   },
 * });
 */
export function useGetInnByPatentNumberQuery(baseOptions: Apollo.QueryHookOptions<GetInnByPatentNumberQuery, GetInnByPatentNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInnByPatentNumberQuery, GetInnByPatentNumberQueryVariables>(GetInnByPatentNumberDocument, options);
      }
export function useGetInnByPatentNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInnByPatentNumberQuery, GetInnByPatentNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInnByPatentNumberQuery, GetInnByPatentNumberQueryVariables>(GetInnByPatentNumberDocument, options);
        }
export type GetInnByPatentNumberQueryHookResult = ReturnType<typeof useGetInnByPatentNumberQuery>;
export type GetInnByPatentNumberLazyQueryHookResult = ReturnType<typeof useGetInnByPatentNumberLazyQuery>;
export type GetInnByPatentNumberQueryResult = Apollo.QueryResult<GetInnByPatentNumberQuery, GetInnByPatentNumberQueryVariables>;
export const CreatePatentInvoiceDocument = gql`
    mutation CreatePatentInvoice($actionId: String!, $captcha: String!, $firstName: String!, $lastName: String!, $middleName: String, $monthCount: Int!, $patentNumber: String!, $patentSerial: String!) {
  createPatentInvoice(
    input: {actionId: $actionId, captcha: $captcha, firstName: $firstName, lastName: $lastName, middleName: $middleName, monthCount: $monthCount, patentNumber: $patentNumber, patentSerial: $patentSerial}
  ) {
    pdf {
      url
      date
      originalname
      size
    }
  }
}
    `;
export type CreatePatentInvoiceMutationFn = Apollo.MutationFunction<CreatePatentInvoiceMutation, CreatePatentInvoiceMutationVariables>;

/**
 * __useCreatePatentInvoiceMutation__
 *
 * To run a mutation, you first call `useCreatePatentInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatentInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatentInvoiceMutation, { data, loading, error }] = useCreatePatentInvoiceMutation({
 *   variables: {
 *      actionId: // value for 'actionId'
 *      captcha: // value for 'captcha'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      middleName: // value for 'middleName'
 *      monthCount: // value for 'monthCount'
 *      patentNumber: // value for 'patentNumber'
 *      patentSerial: // value for 'patentSerial'
 *   },
 * });
 */
export function useCreatePatentInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreatePatentInvoiceMutation, CreatePatentInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePatentInvoiceMutation, CreatePatentInvoiceMutationVariables>(CreatePatentInvoiceDocument, options);
      }
export type CreatePatentInvoiceMutationHookResult = ReturnType<typeof useCreatePatentInvoiceMutation>;
export type CreatePatentInvoiceMutationResult = Apollo.MutationResult<CreatePatentInvoiceMutation>;
export type CreatePatentInvoiceMutationOptions = Apollo.BaseMutationOptions<CreatePatentInvoiceMutation, CreatePatentInvoiceMutationVariables>;
export const GetPaymentStatusDocument = gql`
    query getPaymentStatus($paymentUUID: String!) {
  getPaymentStatus(input: {paymentUUID: $paymentUUID}) {
    status
  }
}
    `;

/**
 * __useGetPaymentStatusQuery__
 *
 * To run a query within a React component, call `useGetPaymentStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentStatusQuery({
 *   variables: {
 *      paymentUUID: // value for 'paymentUUID'
 *   },
 * });
 */
export function useGetPaymentStatusQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentStatusQuery, GetPaymentStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentStatusQuery, GetPaymentStatusQueryVariables>(GetPaymentStatusDocument, options);
      }
export function useGetPaymentStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentStatusQuery, GetPaymentStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentStatusQuery, GetPaymentStatusQueryVariables>(GetPaymentStatusDocument, options);
        }
export type GetPaymentStatusQueryHookResult = ReturnType<typeof useGetPaymentStatusQuery>;
export type GetPaymentStatusLazyQueryHookResult = ReturnType<typeof useGetPaymentStatusLazyQuery>;
export type GetPaymentStatusQueryResult = Apollo.QueryResult<GetPaymentStatusQuery, GetPaymentStatusQueryVariables>;
export const CreatePaymentUrlDocument = gql`
    mutation CreatePaymentUrl($actionId: String!, $captcha: String!, $firstName: String!, $lastName: String!, $middleName: String, $monthCount: Int!, $patentNumber: String!, $patentSerial: String!, $urlPath: String!) {
  createPaymentUrl(
    input: {actionId: $actionId, captcha: $captcha, firstName: $firstName, lastName: $lastName, middleName: $middleName, monthCount: $monthCount, patentNumber: $patentNumber, patentSerial: $patentSerial, urlPath: $urlPath}
  ) {
    url
  }
}
    `;
export type CreatePaymentUrlMutationFn = Apollo.MutationFunction<CreatePaymentUrlMutation, CreatePaymentUrlMutationVariables>;

/**
 * __useCreatePaymentUrlMutation__
 *
 * To run a mutation, you first call `useCreatePaymentUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentUrlMutation, { data, loading, error }] = useCreatePaymentUrlMutation({
 *   variables: {
 *      actionId: // value for 'actionId'
 *      captcha: // value for 'captcha'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      middleName: // value for 'middleName'
 *      monthCount: // value for 'monthCount'
 *      patentNumber: // value for 'patentNumber'
 *      patentSerial: // value for 'patentSerial'
 *      urlPath: // value for 'urlPath'
 *   },
 * });
 */
export function useCreatePaymentUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentUrlMutation, CreatePaymentUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentUrlMutation, CreatePaymentUrlMutationVariables>(CreatePaymentUrlDocument, options);
      }
export type CreatePaymentUrlMutationHookResult = ReturnType<typeof useCreatePaymentUrlMutation>;
export type CreatePaymentUrlMutationResult = Apollo.MutationResult<CreatePaymentUrlMutation>;
export type CreatePaymentUrlMutationOptions = Apollo.BaseMutationOptions<CreatePaymentUrlMutation, CreatePaymentUrlMutationVariables>;
export const GetApplicationDocument = gql`
    query GetApplication($number: String!, $actionId: String!, $captcha: String!) {
  getApplication(input: {number: $number, actionId: $actionId, captcha: $captcha}) {
    message
    number
  }
}
    `;

/**
 * __useGetApplicationQuery__
 *
 * To run a query within a React component, call `useGetApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationQuery({
 *   variables: {
 *      number: // value for 'number'
 *      actionId: // value for 'actionId'
 *      captcha: // value for 'captcha'
 *   },
 * });
 */
export function useGetApplicationQuery(baseOptions: Apollo.QueryHookOptions<GetApplicationQuery, GetApplicationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApplicationQuery, GetApplicationQueryVariables>(GetApplicationDocument, options);
      }
export function useGetApplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationQuery, GetApplicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApplicationQuery, GetApplicationQueryVariables>(GetApplicationDocument, options);
        }
export type GetApplicationQueryHookResult = ReturnType<typeof useGetApplicationQuery>;
export type GetApplicationLazyQueryHookResult = ReturnType<typeof useGetApplicationLazyQuery>;
export type GetApplicationQueryResult = Apollo.QueryResult<GetApplicationQuery, GetApplicationQueryVariables>;
export const GetMonthNdflSumDocument = gql`
    query GetMonthNdflSum {
  getMonthNdflSum {
    changeDate
    ndflSum
    subjectName
  }
}
    `;

/**
 * __useGetMonthNdflSumQuery__
 *
 * To run a query within a React component, call `useGetMonthNdflSumQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonthNdflSumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonthNdflSumQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMonthNdflSumQuery(baseOptions?: Apollo.QueryHookOptions<GetMonthNdflSumQuery, GetMonthNdflSumQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMonthNdflSumQuery, GetMonthNdflSumQueryVariables>(GetMonthNdflSumDocument, options);
      }
export function useGetMonthNdflSumLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMonthNdflSumQuery, GetMonthNdflSumQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMonthNdflSumQuery, GetMonthNdflSumQueryVariables>(GetMonthNdflSumDocument, options);
        }
export type GetMonthNdflSumQueryHookResult = ReturnType<typeof useGetMonthNdflSumQuery>;
export type GetMonthNdflSumLazyQueryHookResult = ReturnType<typeof useGetMonthNdflSumLazyQuery>;
export type GetMonthNdflSumQueryResult = Apollo.QueryResult<GetMonthNdflSumQuery, GetMonthNdflSumQueryVariables>;
export const PatentCheckDocument = gql`
    query PatentCheck($patentNumber: String!, $patentSerial: String!) {
  patentCheck(input: {patentNumber: $patentNumber, patentSerial: $patentSerial}) {
    resultCode
    status
    monthNdflSum
  }
}
    `;

/**
 * __usePatentCheckQuery__
 *
 * To run a query within a React component, call `usePatentCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatentCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatentCheckQuery({
 *   variables: {
 *      patentNumber: // value for 'patentNumber'
 *      patentSerial: // value for 'patentSerial'
 *   },
 * });
 */
export function usePatentCheckQuery(baseOptions: Apollo.QueryHookOptions<PatentCheckQuery, PatentCheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatentCheckQuery, PatentCheckQueryVariables>(PatentCheckDocument, options);
      }
export function usePatentCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatentCheckQuery, PatentCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatentCheckQuery, PatentCheckQueryVariables>(PatentCheckDocument, options);
        }
export type PatentCheckQueryHookResult = ReturnType<typeof usePatentCheckQuery>;
export type PatentCheckLazyQueryHookResult = ReturnType<typeof usePatentCheckLazyQuery>;
export type PatentCheckQueryResult = Apollo.QueryResult<PatentCheckQuery, PatentCheckQueryVariables>;
export const GetMyLocaleIpDocument = gql`
    query GetMyLocaleIp {
  getMyLocation {
    countryCode
    suggestedLanguage
  }
}
    `;

/**
 * __useGetMyLocaleIpQuery__
 *
 * To run a query within a React component, call `useGetMyLocaleIpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyLocaleIpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyLocaleIpQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyLocaleIpQuery(baseOptions?: Apollo.QueryHookOptions<GetMyLocaleIpQuery, GetMyLocaleIpQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyLocaleIpQuery, GetMyLocaleIpQueryVariables>(GetMyLocaleIpDocument, options);
      }
export function useGetMyLocaleIpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyLocaleIpQuery, GetMyLocaleIpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyLocaleIpQuery, GetMyLocaleIpQueryVariables>(GetMyLocaleIpDocument, options);
        }
export type GetMyLocaleIpQueryHookResult = ReturnType<typeof useGetMyLocaleIpQuery>;
export type GetMyLocaleIpLazyQueryHookResult = ReturnType<typeof useGetMyLocaleIpLazyQuery>;
export type GetMyLocaleIpQueryResult = Apollo.QueryResult<GetMyLocaleIpQuery, GetMyLocaleIpQueryVariables>;