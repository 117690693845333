import { memo, useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { IconButton, IconButtonVariant } from 'components/IconButton';
import { IconsTypes } from 'components/Icon';
import { Button, ButtonVariant } from 'components/Button';
import { TUseToggleReturn } from 'hooks/useToggle';
import { useIsDesktop, useIsDesktopMedium } from 'hooks/useBreakpoints';
import { BurgerMenu, BurgerMenuProps } from '../BurgerMenu';
import { LangSwitcher } from './LangSwitcher';
import { Search } from './Search';
import s from './MenuItemsRight.module.scss';

export interface MenuItemsRightProps {
  className?: string;
  menuOptions?: BurgerMenuProps['menuOptions'];
  searchToggle: TUseToggleReturn;
  languageToggle: TUseToggleReturn;
  burgerToggle: TUseToggleReturn;
}

export const MenuItemsRight = memo((props: MenuItemsRightProps) => {
  const { className, menuOptions, searchToggle, languageToggle, burgerToggle } = props;

  const [isSearchOpen, { toggle: toggleSearch, unset: closeSearch }] = searchToggle;
  const [isLanguageMenuOpen, { toggle: toggleLanguageMenu, unset: closeLanguageMenu }] = languageToggle;
  const [isBurgerOpen, { toggle: toggleBurgerMenu, unset: closeBurgerMenu }] = burgerToggle;

  const isDesktop = useIsDesktop();
  const isDesktopMedium = useIsDesktopMedium();

  const menuRef = useRef<HTMLDivElement>(null);

  const closeAllDropdowns = useCallback(() => {
    closeBurgerMenu();
    closeLanguageMenu();
  }, [closeLanguageMenu, closeBurgerMenu]);

  return (
    <div className={clsx(s.MenuItemsRight, className)} ref={menuRef}>
      <Search isOpen={isSearchOpen} toggleSearch={toggleSearch} closeSearch={closeSearch} />
      <LangSwitcher
        isOpen={isLanguageMenuOpen}
        toggleLanguageMenu={toggleLanguageMenu}
        closeLanguageMenu={closeLanguageMenu}
      />

      {isDesktopMedium ? (
        <>
          <Button
            Component={Link}
            to={
              'https://mmc.mos.ru/client-office/security/auth-rvg/login/check?3&service=http://mmc.mos.ru/client-office/auth/signin-cas'
            }
            target={'_blank'}
            rel={'noopener noreferrer'}
            variant={ButtonVariant.PrimaryBlue}
            iconLeft={IconsTypes.USER}>
            <FormattedMessage defaultMessage={'РВП, ВНЖ, Гражданство РФ'} />
          </Button>
          <Button
            Component={Link}
            to={'https://oibmmc.mos.ru/auth-mmc-lk/login?1&service=http://lkmmc.mos.ru/mmcinfosupplier/oib-auth/check'}
            target={'_blank'}
            rel={'noopener noreferrer'}
            iconLeft={IconsTypes.USER}>
            <FormattedMessage defaultMessage={'Патент'} />
          </Button>
        </>
      ) : (
        <IconButton
          variant={IconButtonVariant.Tertiary}
          icon={
            !isDesktop && (isBurgerOpen || isLanguageMenuOpen || isSearchOpen) ? IconsTypes.CROSS : IconsTypes.BURGER
          }
          onClick={
            !isDesktop && (isBurgerOpen || isLanguageMenuOpen || isSearchOpen) ? closeAllDropdowns : toggleBurgerMenu
          }
        />
      )}

      {!isDesktopMedium && isBurgerOpen && (
        <BurgerMenu rootRef={menuRef} menuOptions={menuOptions} closeBurgerMenu={closeBurgerMenu} />
      )}
    </div>
  );
});

MenuItemsRight.displayName = 'MenuItemsRight';
