import { IntlProvider as IntlProviderDefault } from 'react-intl';
import { ResolvedIntlConfig } from '@formatjs/intl/src/types';
import { ReactFCC } from 'utils/helperTypes';
import { DEFAULT_LOCALE, useLocale } from 'store/locale';

const messagesCache: {
  [key in string]?: ResolvedIntlConfig['messages'] | null;
} = {};

function getMessages(locale: string) {
  if (messagesCache[locale] !== undefined) {
    return messagesCache[locale];
  }
  throw loadMessages(locale);
}

async function loadMessages(locale: string) {
  try {
    const messages = await import(`locales/compiled/${locale}.json`);
    messagesCache[locale] = messages;
    return messages;
  } catch (e) {
    messagesCache[locale] = null;
    if (locale !== DEFAULT_LOCALE) {
      await loadMessages(DEFAULT_LOCALE);
    } else {
      return null;
    }
  }
}

export const IntlProvider: ReactFCC = ({ children }) => {
  const { locale, loading, error } = useLocale();

  if (loading && !error) {
    return null;
  }

  const messages = getMessages(locale.name);

  return (
    <IntlProviderDefault
      locale={locale.name}
      defaultLocale={DEFAULT_LOCALE}
      messages={messages ?? {}}
      onError={() => undefined}>
      {children}
    </IntlProviderDefault>
  );
};
