import { memo } from 'react';
import clsx from 'clsx';
import { Divider } from 'components/Divider';
import { TopFooter } from './TopFooter';
import { BottomFooter } from './BottomFooter';
import s from './Footer.module.scss';

export interface FooterProps {
  className?: string;
}
export const Footer = memo(({ className }: FooterProps) => {
  return (
    <footer className={clsx(s.Footer, className)}>
      <TopFooter />
      <Divider />
      <BottomFooter />
    </footer>
  );
});

Footer.displayName = 'Footer';
