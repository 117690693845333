import { memo, useCallback, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import clsx from 'clsx';
import { Options } from '@popperjs/core';
import { Icon, IconSize, IconsTypes } from 'components/Icon';
import { DropdownMenu, DropdownMenuItem } from 'components/DropdownMenu';
import { useClickOutside } from 'hooks/useClickOutside';
import { Portal } from 'components/Portal';
import { ILocale, useLocale } from 'store/locale';
import { useUpdateLangQueryParam } from './useUpdateLangQueryParam';
import { LanguageTooltip } from './components/LanguageTooltip';
import s from './LangSwitcher.module.scss';

export interface LangSwitcherProps {
  className?: string;
  isOpen?: boolean;
  toggleLanguageMenu: () => void;
  closeLanguageMenu: () => void;
}

const popperOptions: Partial<Options> = {
  strategy: 'fixed',
  placement: 'bottom-start',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [-20, 10]
      }
    },
    {
      name: 'preventOverflow',
      options: {
        rootBoundary: 'viewport',
        tether: false,
        altAxis: true
      }
    }
  ]
};

export const LangSwitcher = memo((props: LangSwitcherProps) => {
  const { className, toggleLanguageMenu, closeLanguageMenu, isOpen } = props;

  const { locale, availableLocales, setLocale, visibleLanguageTooltip, languageTooltipHandler } = useLocale();

  const rootRef = useRef<HTMLDivElement>(null);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(rootRef.current, popperElement, {
    ...popperOptions
  });

  useClickOutside(dropdownMenuRef, isOpen ? closeLanguageMenu : null);

  const updateLangQueryParam = useUpdateLangQueryParam();

  const onLocaleClick = useCallback(
    (newLocale: ILocale) => () => {
      setLocale(newLocale);
      updateLangQueryParam(newLocale.name);
      closeLanguageMenu();
    },
    [closeLanguageMenu, setLocale, updateLangQueryParam]
  );

  if (availableLocales.length < 2) {
    return null;
  }

  return (
    <div className={s.LangSwitcher__wrapper}>
      <div ref={rootRef} className={clsx(s.LangSwitcher, className)} onClick={toggleLanguageMenu}>
        <Icon className={s.LangSwitcher__icon} icon={IconsTypes.CIRCLE} size={IconSize.Small} />
        <p className={s.LangSwitcher__text}>{locale.displayName}</p>
        {isOpen && (
          <Portal>
            <div
              className={s.LangSwitcher__dropdownMenu}
              ref={setPopperElement}
              style={styles.popper}
              onClick={(e) => e.stopPropagation()}
              {...attributes.popper}>
              <DropdownMenu
                ref={dropdownMenuRef}
                classes={{ root: s.LangSwitcher__dropdownMenuRoot, items: s.LangSwitcher__dropdownMenuItems }}>
                {availableLocales.map((option) => (
                  <DropdownMenuItem
                    key={option.name}
                    onClick={onLocaleClick(option)}
                    isActive={locale.name === option.name}>
                    {option.displayName}
                  </DropdownMenuItem>
                ))}
              </DropdownMenu>
            </div>
          </Portal>
        )}
      </div>
      {visibleLanguageTooltip && (
        <LanguageTooltip setVisible={languageTooltipHandler} changeLanguage={toggleLanguageMenu} />
      )}
    </div>
  );
});

LangSwitcher.displayName = 'LangSwitcher';
