import { memo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import s from './SocialButton.module.scss';

export interface SocialButtonProps {
  icon?: string;
  title?: string;
  url: string;
  className?: string;
}

export const SocialButton = memo((props: SocialButtonProps) => {
  const { className, title, icon, url } = props;
  if (!icon) {
    return null;
  }

  return (
    <Link className={clsx(s.SocialButton, className)} to={url} target={'_blank'} rel={'noopener noreferrer'}>
      <img className={s.SocialButton__img} src={icon} alt={title} />
    </Link>
  );
});

SocialButton.displayName = 'SocialButton';
