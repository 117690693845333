import { FormattedMessage } from 'react-intl';
import { Hero, HeroContent } from 'components/Hero';

export const ErrorPage = () => {
  return (
    <Hero align={'center'}>
      <HeroContent
        title={<FormattedMessage defaultMessage={'Ошибка в настройках сайта'} />}
        text={<FormattedMessage defaultMessage={'Мы уже работаем над ее исправлением'} />}
      />
    </Hero>
  );
};
